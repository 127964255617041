import { ApolloError, useQuery } from '@apollo/client'
import {
  CustomerInfoDocument,
  CustomerInfoQuery
} from '@local/api/generated/gql/graphql'

export const useCustomerInfo = (): {
  data?: CustomerInfoQuery
  loading: boolean
  error: ApolloError | undefined
} => {
  const { data, loading, error } =
    useQuery<CustomerInfoQuery>(CustomerInfoDocument)

  return { data, loading, error }
}
