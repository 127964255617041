import * as React from 'react'
import { AppProviders } from './AppProviders'
import { PayrollOnboardingChecklist } from '../../packages/app-checklist'

/**
 * This is the full application
 */
const App = () => (
  <AppProviders>
    <PayrollOnboardingChecklist />
  </AppProviders>
)

export { App }
