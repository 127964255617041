import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import {
  ChecklistItemsDocument,
  ChecklistItemsMutation,
  ChecklistItemsMutationVariables
} from '@local/api/generated/gql/graphql'

export const useResync = (): {
  loading: boolean
  error?: ApolloError | undefined
  resync: MutationTuple<
    ChecklistItemsMutation,
    ChecklistItemsMutationVariables
  >[0]
} => {
  const [resync, { loading, error }] = useMutation(ChecklistItemsDocument)

  return { resync, loading, error }
}
