import * as React from 'react'
import cx from 'classnames'
import '../styles.css'

export type CustomListOptionType = {
  title: string
  description?: string
}

export const CustomListOption = ({
  data,
  options
}: {
  data: CustomListOptionType[]
  options?: { isBold: boolean }
}) => {
  return (
    <div className='pl-2 ml-2 space-y-4 divide-y'>
      {data.map((option, i) => (
        <OptionItem
          key={`${option.title}-${i}`}
          title={option.title}
          description={option.description}
          bold={options?.isBold}
        />
      ))}
    </div>
  )
}

const OptionItem = ({
  title,
  description,
  bold = true
}: {
  title: string
  description: React.ReactNode
  bold?: boolean
}) => (
  <li key={title} className='pt-3'>
    <div className={cx('mb-2', bold && 'font-bold')}>{title}</div>
    <div>{description}</div>
  </li>
)
