import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { useFeins } from '../../hooks/graph/useFeins'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { useFirstPayroll } from '../../hooks/graph/useFirstPayroll'
import { Loader } from '../../common/loader'
import { useUser } from '@toasttab/ec-session'
import TeamPng from './team.png'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import {
  PayrollEmployeeIcon,
  PayrollSetupIcon,
  RestaurantIcon,
  LocationIcon
} from '@toasttab/buffet-pui-icons'
import { useCustomerInfo } from '../../hooks/graph/useCustomerInfo'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { Trans, useTranslation } from 'react-i18next'
import { useUpdatePreviousProvider } from '../../hooks/graph/mutation/useUpdatePreviousProvider'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'

const BusinessInfo = ({ items }: { items: string[] }) => (
  <>
    <div className='mx-4 mb-4 type-overline text-secondary'>Business Info</div>
    <CardContainer noElevation noPadding className='p-4 mb-6 space-y-3'>
      <div>
        <PayrollSetupIcon accessibility='decorative' className='mr-1.5' />
        {items[0]}
      </div>
      <div>
        <RestaurantIcon accessibility='decorative' className='mr-1.5' />
        {items[1]}
      </div>
      <div>
        <LocationIcon accessibility='decorative' className='mr-1.5' />
        {items[2]}
      </div>
      <div>
        <PayrollEmployeeIcon accessibility='decorative' className='mr-1.5' />
        {items[3]}
      </div>
    </CardContainer>
  </>
)

export const WelcomeScreen = () => {
  const { t } = useTranslation('ponc')

  const { name } = useUser()

  const { data: feins, loading: loadingFeins } = useFeins()
  const { data: customerInfo, loading: loadingCustomerInfo } = useCustomerInfo()
  const { date, dateFormatted, loading: loadingPayroll } = useFirstPayroll()

  const { updatePreviousProvider } = useUpdatePreviousProvider()
  const { updateTask } = useUpdateTask()

  const [isSwitcher, setIsSwitcher] = React.useState<null | boolean>(null)

  React.useEffect(() => {
    if (customerInfo) {
      setIsSwitcher(customerInfo.customerSettings.HAS_PREVIOUS_PAYROLL_PROVIDER)
    }
  }, [customerInfo])

  const loading = loadingFeins || loadingCustomerInfo || loadingPayroll
  const missingData = !feins || !customerInfo

  if (loading || missingData) {
    return <Loader />
  }

  const fein = feins[0]
  const isMulti = feins.length > 1

  const year = date?.toFormat({ year: 'numeric' })

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='flex flex-col mb-4 overflow-x-hidden overflow-y-auto'>
        <div className='flex flex-col items-center justify-between mb-4'>
          <img
            src={TeamPng}
            alt={t('welcome.imgAlt')}
            // image has some built in padding
            className='w-2/5 mb-6'
          />
          <div className='font-bold type-large'>{t('welcome.title')}</div>
        </div>
        <div className='p-4 mb-6 rounded bg-gray-0 type-caption'>
          <Trans
            t={t}
            i18nKey='welcome.firstCheck'
            components={[
              <span key='dateformatted' className='font-semibold'>
                {dateFormatted}
              </span>
            ]}
            values={{ date: dateFormatted }}
          />
        </div>

        <CardSelectorGroup
          label={
            isMulti
              ? t('welcome.previousProvider.questionMulti', { year: year })
              : t('welcome.previousProvider.question', { year: year })
          }
          className='mb-6'
          itemsContainerClassName='grid-cols-2'
          options={[
            {
              value: 'yes',
              contents: t('welcome.previousProvider.yes'),
              checked: isSwitcher === true
            },
            {
              value: 'no',
              contents: t('welcome.previousProvider.no'),
              checked: isSwitcher === false
            }
          ]}
          helperIconButton={
            <InfoTooltip cropToIcon>
              <div className='text-center'>
                {t('welcome.previousProvider.tooltip')}
              </div>
            </InfoTooltip>
          }
          onChange={(e) => {
            setIsSwitcher(e?.target.value === 'yes')
          }}
        />

        <BusinessInfo
          items={
            isMulti
              ? [
                  t('welcome.businessInfo.businesses', { count: feins.length }),
                  t('welcome.businessInfo.restaurants', {
                    count: customerInfo.customer.locations.filter(
                      ({ status }) => status.code !== 'RestaurantGuidEmpty'
                    ).length
                  }),
                  t('welcome.businessInfo.locations', {
                    count:
                      customerInfo.customerConfiguration?.workTaxLocations
                        ?.length ?? 0
                  }),
                  name
                ]
              : [
                  `FEIN ${fein.tin}`,
                  customerInfo.payrollCustomer.name,
                  customerInfo.customerConfiguration?.workTaxLocations[0]
                    .name ?? '',
                  name
                ]
          }
        />

        <div className='mx-4 mb-4 type-overline text-secondary'>
          {t('welcome.whatYouWillNeed.title')}
        </div>
        <CardContainer noElevation noPadding className='p-4 mb-2'>
          <ul className='ml-5 space-y-2'>
            <li>{t('welcome.whatYouWillNeed.items.legal')}</li>
            <li>{t('welcome.whatYouWillNeed.items.deposit')}</li>
            <li>{t('welcome.whatYouWillNeed.items.federalTax')}</li>
            <li>{t('welcome.whatYouWillNeed.items.stateTax')}</li>
            <li>{t('welcome.whatYouWillNeed.items.onboarding')}</li>
            <li>{t('welcome.whatYouWillNeed.items.jobs')}</li>
          </ul>
        </CardContainer>
      </div>
      <div className='flex w-full'>
        <Button
          size='lg'
          className='flex-grow'
          onClick={() => {
            updatePreviousProvider(isSwitcher as boolean)
            updateTask(
              ChecklistItemKey.PAYROLL_ACTIVITY_GET_STARTED_BUTTON_CLICK,
              TaskStatus.COMPLETED
            )
          }}
          disabled={isSwitcher === null}
        >
          {t('welcome.getStarted')}
        </Button>
      </div>
    </div>
  )
}
