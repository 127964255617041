import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import '../styles.css'
import { ItemSubTask } from '../common/ItemSubTask'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import {
  CustomListOption,
  CustomListOptionType
} from '../common/OptionExplanation'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { disableItemActions } from '../../utils/status'
import { Item } from '@local/api/generated/gql/graphql'
import { useTranslation } from 'react-i18next'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'

const Content = ({
  content,
  examples
}: {
  content: string
  examples: string
}) => {
  const { t } = useTranslation('ponc')
  const item = ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_DOCS
  const documents = [
    { title: t(`${item}.documents.handbook`) },
    { title: t(`${item}.documents.business`) },
    { title: t(`${item}.documents.forms`) }
  ] as CustomListOptionType[]
  return (
    <>
      <p>{content}</p>
      <p className='mb-2'>{examples}</p>
      <CustomListOption data={documents} options={{ isBold: false }} />
    </>
  )
}

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_DOCS,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  return (
    <>
      <Button
        iconLeft={<AddIcon accessibility='decorative' />}
        variant='secondary'
        onClick={() => setChecklistOpen(false)}
      >
        {t(`${item.key}.add`)}
      </Button>
      <Button disabled={disableItemActions(item)} onClick={complete}>
        {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
      </Button>
    </>
  )
}

export const RequiredDocumentation = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      title={t(`${item.key}.stepTitle`)}
      content={
        <Content
          content={t(`${item.key}.content`)}
          examples={t(`${item.key}.example`)}
        />
      }
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
