export const getPOSBase = (): string => {
  switch (getEnv(window.location.href)) {
    case 'local':
    case 'dev':
      return 'https://dev.eng.toastteam.com'
    case 'preprod':
      return 'https://preprod.eng.toasttab.com'
    default:
      return 'https://www.toasttab.com'
  }
}

type EnvironmentLabel = 'prod' | 'preprod' | 'qa' | 'dev' | 'local'

const environmentMap: Map<string, EnvironmentLabel> = new Map([
  ['preprod.eng.toasttab', 'preprod'],
  ['web.qastratex', 'qa'],
  ['web.devstratex', 'dev'],
  ['localhost', 'local'],
  ['toastteam', 'local'],
  ['http://', 'local']
])

const getEnv = (url: string): EnvironmentLabel => {
  const env = Array.from(environmentMap.entries()).reduce((total, entry) => {
    const [key, value] = entry
    if (url.indexOf(key) > -1) {
      return value
    } else {
      return total
    }
  }, 'prod')
  return env as EnvironmentLabel
}
