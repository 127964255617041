import {
  Configuration,
  makeEcSpaCompanyLocalStorageState
} from '@toasttab/ec-storage'

type LocalStorageTypes = {
  'last-defaulted-open': number | null // unix epoch
}

const LocalStorageConfiguration: Configuration<LocalStorageTypes> = {
  'last-defaulted-open': {
    default: null,
    isValid: (val) => val === null || typeof val === 'number'
  }
}

const { useEcSpaCompanyLocalStorageState: useLocalStorageState } =
  makeEcSpaCompanyLocalStorageState<LocalStorageTypes>({
    spaName: 'ec-payroll-onboarding-checklist-spa',
    configuration: LocalStorageConfiguration
  })

export { useLocalStorageState }
