import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import '../styles.css'
import { ItemSubTask } from '../common/ItemSubTask'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { useTranslation } from 'react-i18next'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'

import { disableItemActions } from '../../utils/status'
import { Item } from '@local/api/generated/gql/graphql'

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_REVIEW_MISSING_INFO,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  return (
    <>
      <Button variant='secondary' onClick={() => setChecklistOpen(false)}>
        {t(`${item.key}.review`)}
      </Button>
      <Button onClick={complete} disabled={disableItemActions(item)}>
        {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
      </Button>
    </>
  )
}

export const MissingInfo = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      title={t(`${item.key}.header`)}
      content={<p>{t(`${item.key}.check`)}</p>}
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
