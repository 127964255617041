import * as React from 'react'

export const ItemSubTask = ({
  header,
  title,
  content,
  footer
}: {
  header?: React.JSX.Element
  title: string
  content: React.JSX.Element
  footer?: React.JSX.Element
}) => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='flex flex-col mb-4 overflow-y-auto'>
        {header}
        <div className='mb-2 font-bold'>{title}</div>
        <div className='mb-2'>{content}</div>
      </div>
      {/*
        NOTE: This design causes the overflow scroll bar to appear slightly compressed, since it's not on the most outermost container.
        It's possible to fix this by hoisting the footer to the main checklist, but it would make the code much more complex -- only fix if a design priority.
      */}
      <div className='flex flex-col py-4 mb-0 space-y-4'>{footer}</div>
    </div>
  )
}
