export class SpaDate {
  private readonly that: Date
  private constructor(_that: Date) {
    this.that = _that
  }

  // When the date format is ISO (e.g., 2024-11-01) then Date will convert the string to
  // a UTC timestamp, and then localize, which will produce 2024-10-31 if the browser is
  // in the USA.
  //
  // Use this if the caller knows that the format will undergo time zone conversion.
  static fromISOString(utcFormattedDate: string): SpaDate {
    const dateTranslatedToLocalZone = new Date(utcFormattedDate)
    return new SpaDate(
      new Date(
        // must offset because the date comes as local TZ, not UTC
        dateTranslatedToLocalZone.getTime() +
          dateTranslatedToLocalZone.getTimezoneOffset() * 60 * 1000
      )
    )
  }

  static fromString(obj: unknown): SpaDate | undefined {
    if (obj instanceof SpaDate) {
      return obj
    }
    if (!obj) {
      return undefined
    }
    return obj?.toString() ? new SpaDate(new Date(obj?.toString())) : undefined
  }

  // will produce this output: August 11, 2023
  toLongFormat = (): string =>
    this.toFormat({
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

  toFormat = (options: any, locale = 'en-US'): string => {
    return new Intl.DateTimeFormat(locale, options).format(this.that)
  }
}
