import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ItemSubTask } from '../common/ItemSubTask'
import { useFeins } from '../../hooks/graph/useFeins'
import { Item } from '@local/api/generated/gql/graphql'
import { disableItemActions } from '../../utils/status'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../common/loader'
import { Error } from '../../common/error'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'

export const FederalTaxInfo = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { data, loading, error } = useFeins()
  const feins = data && data.length ? data : undefined

  const { isLoading, updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_FEDERAL_TAX,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  if (loading) return <Loader />

  if (error || !feins) return <Error />

  const isMultiLocation = feins.length > 1

  return (
    <ItemSubTask
      title={t(`${item.key}.stepTitle`)}
      content={
        <div className='flex flex-col space-y-6'>
          <p>{t(`${item.key}.description`)}</p>
          <Table>
            <Head>
              <Row>
                <HeadingCell>{t(`${item.key}.businessInfo`)}</HeadingCell>
                {isMultiLocation && (
                  <HeadingCell className='text-right'>
                    {t(`${item.key}.locations`)}
                  </HeadingCell>
                )}
              </Row>
            </Head>
            <Body>
              {feins.map((fein, index) => (
                <Row key={index}>
                  <Cell key={`fein.name${index}`}>
                    <div>{fein.name}</div>
                    <div className='text-secondary'>{fein.tin}</div>
                  </Cell>
                  {isMultiLocation && (
                    <Cell
                      key={`wtl.${index}`}
                      className='text-right align-middle'
                    >
                      {fein.workTaxLocationIds.length}
                    </Cell>
                  )}
                </Row>
              ))}
            </Body>
          </Table>
        </div>
      }
      footer={
        <Button
          disabled={isLoading || disableItemActions(item)}
          onClick={complete}
        >
          {isAdmin ? t('common.complete') : t(`${item.key}.title`)}
        </Button>
      }
    />
  )
}
