import { graphql } from '../generated/gql'

// these are generally not used -- prefer passing generated query Document directly to Apollo

export const ChecklistItems = graphql(`
  mutation ChecklistItems {
    checklistState: resyncTasks {
      customerUuid
      items {
        # change these to fragments if the list of item data grows
        key
        status
        title
        assignedTo
        subItems {
          key
          status
          title
          assignedTo
        }
      }
    }
  }
`)

export const PaycheckDeliveryMethod = graphql(`
  query PaycheckDeliveryMethod {
    paycheckDeliverySettings {
      method
      needsPdfFile
      usingToastCheckStock
    }
  }
`)

export const FindJobsQuery = graphql(`
  query Jobs($parentLevelUuid: String) {
    jobs(parentLevelUuid: $parentLevelUuid) {
      uuid
      name
      isOrphan
    }
  }
`)

export const FindJobsByLocationQuery = graphql(`
  query CustomerConfiguration {
    customerConfiguration {
      locations {
        id
        name
        jobs {
          id
          name
        }
      }
    }
  }
`)

export const Feins = graphql(`
  query Feins {
    feins {
      id
      name
      tin
      isActive
      workTaxLocationIds
      states {
        name
        eins {
          id
          taxName
          ein
          frequency {
            name
          }
          sutaRates {
            rate
            name
            isMajor
            isExempt
            excludeFromCombinedRate
          }
        }
      }
    }
  }
`)

export const OnboardingPermissionsQuery = graphql(`
  query OnboardingPermissions {
    payrollOnboardingPermissions
  }
`)

export const CustomerInfoQuery = graphql(`
  query CustomerInfo {
    # note: due to a bug in the supergraph, even though customer and payrollCustomer return the same type -- we must query fields separately
    customer {
      locations {
        name
        status {
          code
        }
      }
    }
    payrollCustomer {
      name
    }
    customerConfiguration {
      workTaxLocations {
        name
        address {
          state
          country
        }
      }
    }
    customerSettings {
      HAS_PREVIOUS_PAYROLL_PROVIDER
    }
  }
`)

export const ChecklistStateFragment = graphql(`
  fragment ChecklistStateFragment on ChecklistState {
    customerUuid
    items {
      key
      status
      title
      assignedTo
      subItems {
        key
        status
        title
        assignedTo
      }
    }
  }
`)

export const UpdateTask = graphql(`
  mutation UpdateTask($taskKey: String!, $status: TaskStatus!) {
    updateTask(taskKey: $taskKey, status: $status) {
      ...ChecklistStateFragment
    }
  }
`)

export const updatePreviousProvider = graphql(`
  mutation UpdatePreviousProvider($hasPreviousPayrollProvider: Boolean!) {
    setHasPreviousPayrollProvider(
      hasPreviousPayrollProvider: $hasPreviousPayrollProvider
    ) {
      message
    }
  }
`)

export const upcomingPayrolls = graphql(`
  query upcoming {
    upcoming {
      payDate
    }
  }
`)
