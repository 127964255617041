// export from list_states table (`select code, name from list_states where countryId = 344`)
export enum StateUS {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
  AS = 'American Samoa',
  GU = 'Guam',
  MP = 'Northern Mariana Islands',
  PW = 'Republic of Palau',
  PR = 'Puerto Rico',
  VI = 'Virgin Islands'
}

export const PAY_CARD_EXCLUDED_STATES = [
  StateUS.CA,
  StateUS.HI,
  StateUS.MS,
  StateUS.NH,
  StateUS.NV,
  StateUS.NY,
  StateUS.NC,
  StateUS.ND,
  StateUS.SD,
  StateUS.TN,
  StateUS.UT,
  StateUS.VT
]

export const WITHHOLDING_TAX_EXCLUDED_STATES = [
  StateUS.AK,
  StateUS.FL,
  StateUS.NV,
  StateUS.NH,
  StateUS.SD,
  StateUS.TN,
  StateUS.TX,
  StateUS.WA,
  StateUS.WY
]

export const UNEMPLOYMENT_TAX_ALTERNATE_COPY_STATES = [
  StateUS.AL,
  StateUS.AZ,
  StateUS.AR,
  StateUS.CT,
  StateUS.FL,
  StateUS.GA,
  StateUS.IL,
  StateUS.IA,
  StateUS.KY,
  StateUS.LA,
  StateUS.MN,
  StateUS.MS,
  StateUS.MO,
  StateUS.NM,
  StateUS.OH,
  StateUS.OK,
  StateUS.OR,
  StateUS.TX,
  StateUS.UT,
  StateUS.VT,
  StateUS.VA
]

export const TPA_INCLUDED_STATES = [
  StateUS.CO,
  StateUS.HI,
  StateUS.IA,
  StateUS.MA,
  StateUS.MN,
  StateUS.NM,
  StateUS.WV,
  StateUS.WY
]
//if updating TPA Suta or Sit list, remember these are also defined in ec-payroll in TpaTaskRules.kt
export const SUTA_TPA_INCLUDED_STATES = [
  StateUS.CO,
  StateUS.HI,
  StateUS.IA,
  StateUS.MA,
  StateUS.MN,
  StateUS.NM,
  StateUS.WY
]
//if updating TPA Suta or Sit list, remember these are also defined in ec-payroll in TpaTaskRules.kt
export const SIT_TPA_INCLUDED_STATES = [StateUS.HI, StateUS.MN, StateUS.WV]

export const PFML_TPA_INCLUDED_STATES = []
