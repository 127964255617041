import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { TOASTWEB_ROUTES } from '../../routes'
import { getPOSBase } from '../../hooks/useRedirect'
import '../styles.css'
import { Loader } from '../../common/loader'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { ItemSubTask } from '../common/ItemSubTask'
import {
  CustomerConfigurationQuery,
  Item
} from '@local/api/generated/gql/graphql'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { disableItemActions } from '../../utils/status'
import { useTranslation } from 'react-i18next'
import { Error } from '../../common/error'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'
import { useJobsByLocation } from '../../hooks/graph/useJobsByLocation'
import { CustomListOption } from '../common/OptionExplanation'

const Content = ({
  data,
  content,
  subtitle,
  jobs,
  job
}: {
  data: CustomerConfigurationQuery | undefined
  content: string
  subtitle: string
  jobs: string
  job: string
}) => {
  return (
    <>
      <p>{content}</p>
      <p className='border-b-2 text-secondary py-4'>{subtitle}</p>
      {data?.customerConfiguration?.locations.map((location, index) => (
        <AccordionItem
          id={`AccordionItem-${index}`}
          key={`AccordionItem-${index}`}
          title={`${location.name} (${location.jobs.length} ${
            location.jobs.length > 1 ? jobs : job
          })`}
        >
          <CustomListOption
            data={location.jobs.map((job) => ({ title: job.name }))}
            options={{ isBold: false }}
          />
        </AccordionItem>
      ))}
    </>
  )
}

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_JOBS,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  return (
    <>
      <Button
        variant='secondary'
        as='a'
        href={`${getPOSBase()}${TOASTWEB_ROUTES.jobs}`}
        target='_blank'
        iconRight={<LaunchIcon accessibility='decorative' />}
      >
        {t(`${item.key}.add`)}
      </Button>
      <Button onClick={complete} disabled={disableItemActions(item)}>
        {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
      </Button>
    </>
  )
}

export const Jobs = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { error, loading, data: jobsData } = useJobsByLocation()
  const { t } = useTranslation('ponc')

  if (loading) return <Loader />

  if (error || !jobsData) return <Error />

  return (
    <ItemSubTask
      title={t(`${item.key}.title`)}
      content={
        <Content
          data={jobsData}
          content={t(`${item.key}.content`)}
          subtitle={t(`${item.key}.subtitle`)}
          jobs={t(`${item.key}.jobs`)}
          job={t(`${item.key}.job`)}
        />
      }
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
