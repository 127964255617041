import { ApolloError, useQuery } from '@apollo/client'
import { OnboardingPermissionsQuery as OnboardingPermissionsQueryDocument } from '@local/api/documents'
import { OnboardingPermissionsQuery } from '@local/api/generated/gql/graphql'

export const useOnboardingPermissions = (): {
  data?: OnboardingPermissionsQuery
  loading: boolean
  error: ApolloError | undefined
} => {
  const { data, loading, error } = useQuery<OnboardingPermissionsQuery>(
    OnboardingPermissionsQueryDocument
  )

  return { data, loading, error }
}
