import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { useSentry } from 'banquet-runtime-modules'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'
import cx from 'classnames'
import { Checklist } from '@toasttab/buffet-patterns-onboarding-checklist'

export const Error = () => {
  const { t } = useTranslation('ponc')

  return (
    <div className='flex flex-col h-full'>
      <div className='flex flex-col items-center justify-center h-full p-10 space-y-4 text-center'>
        <WarningOutlineIcon accessibility='decorative' />
        {t('common.error')}
      </div>
    </div>
  )
}

// TODO -- extremely lightweight copy of ChecklistContent in PayrollOnboardingChecklist
// If you need to refactor, should first extract out the checklist wrapper in that file, and combine with this component
export const ChecklistError = () => {
  const [checklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  if (!checklistOpen) {
    return null
  }

  return (
    <>
      <div
        className={cx('fixed inset-0 z-0')}
        style={{
          top: '64px'
        }}
      />
      <div
        className='fixed right-0 z-0'
        style={{
          top: '64px',
          width: '380px',
          height: `calc(100vh - 64px)`,
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.09), 0px 8px 19px 1px rgba(0, 0, 0, 0.06), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)'
        }}
      >
        <Checklist
          testId='payroll-checklist'
          headerProps={{}}
          hideHeader
          items={[]}
          contentAlt={<Error />}
        />
      </div>
    </>
  )
}

// TODO -- below components can be replaced by the ec-errors package once that's released

interface ErrorBoundaryProps {
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void
  fallback?: React.ReactNode
  captureException: ReturnType<typeof useSentry>['captureException']
}

class ErrorBoundaryClass extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  { hasError: boolean }
> {
  readonly DEFAULT_STATE = { hasError: false }
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = this.DEFAULT_STATE
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (this.props.onError) {
      this.props.onError(error, errorInfo)
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback !== undefined ? this.props.fallback : null
    }

    return this.props.children
  }
}

export const ErrorBoundary: React.FC<
  React.PropsWithChildren<Omit<ErrorBoundaryProps, 'captureException'>>
> = (props) => {
  const { captureException } = useSentry()

  return <ErrorBoundaryClass {...props} captureException={captureException} />
}
