import { useFragment } from '@apollo/client'
import { ChecklistStateFragment } from '@local/api/documents'
import {
  ChecklistState,
  CustomerInfoQuery
} from '@local/api/generated/gql/graphql'
import { useUser } from '@toasttab/ec-session'
import { useFeature } from '@toasttab/ec-features'
import { Item } from '@local/api/generated/gql/graphql'
import { ChecklistItemKey } from '../../models/checkListItemModels'
import { useCustomerInfo } from './useCustomerInfo'
import { FeatureFlag, useGAView } from '../../models/featureFlag'
import {
  PAY_CARD_EXCLUDED_STATES,
  StateUS,
  TPA_INCLUDED_STATES,
  WITHHOLDING_TAX_EXCLUDED_STATES
} from '../../models/states'

type Key = Item['key']

type Location = NonNullable<
  CustomerInfoQuery['customerConfiguration']
>['workTaxLocations'][number]

const filterItemTree = (items: Item[], condition: (item: Item) => boolean) => {
  const filteredItems: Item[] = []

  items.forEach((item) => {
    if (!condition(item)) return

    filteredItems.push({
      ...item,
      // gql type is wrong -- assumes will be infinitely recursive, but can actually be undefined
      subItems: item.subItems
        ? filterItemTree(item.subItems, condition)
        : undefined
    } as Item)
  })

  return filteredItems
}

const isGetStartedScreen = (key: Key) =>
  key === ChecklistItemKey.PAYROLL_ACTIVITY_GET_STARTED_BUTTON_CLICK

const isExcludedSwitcher = (
  key: Key,
  enableGAView: boolean,
  isSwitcher: boolean
) =>
  key === ChecklistItemKey.PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER &&
  (!enableGAView || !isSwitcher)

const isExcludedPayCard = (key: Key, locations: Location[]) =>
  key === ChecklistItemKey.PAYROLL_PAY_OFFER_TOAST_PAY_CARD &&
  locations.some(
    ({ address: { country, state } }) =>
      country === 'USA' && PAY_CARD_EXCLUDED_STATES.includes(state as StateUS)
  )

const isExcludedWithholdingTax = (key: Key, locations: Location[]) =>
  key === ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX &&
  locations.every(
    ({ address: { country, state } }) =>
      country === 'USA' &&
      WITHHOLDING_TAX_EXCLUDED_STATES.includes(state as StateUS)
  )

const isExcludedTPA = (key: Key, locations: Location[]) =>
  key === ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX &&
  locations.every(
    ({ address: { country, state } }) =>
      !(country === 'USA' && TPA_INCLUDED_STATES.includes(state as StateUS))
  )

const isExcludedBenefits = (key: Key, showBenefitsStep: boolean) =>
  key === ChecklistItemKey.PAYROLL_BENEFITS && !showBenefitsStep

export const useFilterItems = (items?: Item[]): Item[] | undefined => {
  const showBenefitsStep = useFeature(FeatureFlag.SHOW_BENEFITS_STEP, false)

  const { data: customerInfo } = useCustomerInfo()
  const enableGAView = useGAView()

  if (!items || !customerInfo) return undefined

  const isSwitcher =
    !!customerInfo?.customerSettings.HAS_PREVIOUS_PAYROLL_PROVIDER

  const locations = customerInfo.customerConfiguration?.workTaxLocations ?? []

  return filterItemTree(
    items,
    ({ key }) =>
      !(
        isGetStartedScreen(key) ||
        isExcludedSwitcher(key, enableGAView, isSwitcher) ||
        isExcludedPayCard(key, locations) ||
        isExcludedWithholdingTax(key, locations) ||
        isExcludedTPA(key, locations) ||
        isExcludedBenefits(key, showBenefitsStep)
      )
  )
}

export const useChecklistState = (): ChecklistState & {
  itemsDisplay: ReturnType<typeof useFilterItems>
} => {
  const { customerUuid } = useUser()

  const { data } = useFragment<ChecklistState>({
    fragment: ChecklistStateFragment,
    from: {
      __typename: 'ChecklistState',
      customerUuid
    }
  })

  const itemsDisplay = useFilterItems(
    (data as ChecklistState | undefined)?.items
  )

  return {
    items: data.items as ChecklistState['items'],
    customerUuid: data.customerUuid as string,
    itemsDisplay
  }
}
