import { useMutation } from '@apollo/client'
import {
  CustomerInfoDocument,
  UpdatePreviousProviderDocument
} from '@local/api/generated/gql/graphql'

export const useUpdatePreviousProvider = () => {
  const [updatePreviousProvider, { error, loading: isLoading }] = useMutation(
    UpdatePreviousProviderDocument
  )

  return {
    isLoading,
    error,
    updatePreviousProvider: (hasPrevious: boolean) => {
      updatePreviousProvider({
        variables: { hasPreviousPayrollProvider: hasPrevious },
        refetchQueries: [CustomerInfoDocument],
        update: (cache) => {
          cache.modify({
            fields: {
              customerSettings: (existingSettings) => ({
                ...existingSettings,
                HAS_PREVIOUS_PAYROLL_PROVIDER: hasPrevious
              })
            }
          })
        }
      })
    }
  }
}
