import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useTranslation } from 'react-i18next'

export const Loader = () => {
  const { t } = useTranslation('ponc')
  return (
    <div className='flex flex-col items-center justify-center h-full p-10 space-y-4'>
      <MerryGoRound />
      {t('common.loader')}
    </div>
  )
}
