import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ItemSubTask } from '../common/ItemSubTask'
import { Item } from '@local/api/generated/gql/graphql'
import { disableItemActions } from '../../utils/status'
import { Trans, useTranslation } from 'react-i18next'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'

export const LinkPreviousProvider = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { isLoading, updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  return (
    <ItemSubTask
      title={t(`${item.key}.stepTitle`)}
      content={
        <div className='flex flex-col'>
          <p className='pb-3'>{t(`${item.key}.description`)}</p>
          <ul className='pb-4 ml-5 space-y-2 divide-y'>
            <li>{t(`${item.key}.previousConditions.paycheck`)}</li>
            <li className='pt-2'>
              {t(`${item.key}.previousConditions.payrolls`)}
            </li>
            <li className='pt-2'>
              {t(`${item.key}.previousConditions.taxes`)}
            </li>
          </ul>
          <div>
            <AccordionItem
              id='previous-provider'
              title={t(`${item.key}.previousProvider.title`)}
            >
              <p>{t(`${item.key}.previousProvider.text`)}</p>
              <ul className='ml-5 space-y-2 divide-y'>
                <li>
                  {t(`${item.key}.previousProvider.reqs.currentProvider`)}
                </li>
                <li className='pt-2'>
                  {t(`${item.key}.previousProvider.reqs.username`)}
                </li>
                <li className='pt-2'>
                  {t(`${item.key}.previousProvider.reqs.password`)}
                </li>
                <li className='pt-2'>
                  {t(`${item.key}.previousProvider.reqs.phone`)}
                </li>
              </ul>
            </AccordionItem>
            <AccordionItem
              id='accountant'
              title={t(`${item.key}.accountant.title`)}
            >
              <p className='mb-3'>
                <Trans
                  t={t}
                  i18nKey={`${item.key}.accountant.p1`}
                  components={[
                    <a
                      className='underline'
                      href='https://central.toasttab.com/s/article/Toast-Payroll-Previous-payroll-provider-information'
                      target='_blank'
                      rel='noreferrer'
                      key={`${item.key}.accountant.title`}
                    >
                      link, this text is arbitrary
                    </a>
                  ]}
                />
              </p>
              <p>{t(`${item.key}.accountant.p2`)}</p>
            </AccordionItem>
          </div>
        </div>
      }
      footer={
        <>
          <Button
            as='a'
            variant='secondary'
            className='w-full'
            iconRight={<LaunchIcon accessibility='decorative' />}
            target='_blank'
            href='https://central.toasttab.com/s/article/Toast-Payroll-Previous-payroll-provider-information'
          >
            {t(`${item.key}.learnMore`)}
          </Button>
          <Button
            disabled={isLoading || disableItemActions(item)}
            onClick={complete}
          >
            {isAdmin ? t('common.complete') : t('common.confirm')}
          </Button>
        </>
      }
    />
  )
}
