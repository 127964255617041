import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import '../styles.css'
import { ItemSubTask } from '../common/ItemSubTask'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { Item } from '@local/api/generated/gql/graphql'
import { disableItemActions } from '../../utils/status'
import { useTranslation } from 'react-i18next'

const toastTraining = 'https://share.vidyard.com/watch/gvW24VngMP7Exmxjq9CZ3v'

const Content = ({ content, alt }: { content: string; alt: string }) => (
  <div className='w-full'>
    <p>{content}</p>

    <div className='p-4 mb-4 border-2 rounded'>
      <a href={toastTraining} target='blank'>
        <img
          className='w-full'
          src='https://play.vidyard.com/DkaVndnXRN1uyixhsiSsYo.jpg'
          alt={alt}
        />
      </a>
    </div>
  </div>
)

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_REVIEW_COMPLETE_TRAINING,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  if (isAdmin) {
    return (
      <>
        <Button onClick={complete} disabled={disableItemActions(item)}>
          {t('common.complete')}
        </Button>
      </>
    )
  } else {
    return (
      <>
        <Button as='a' variant='secondary' target='_blank' href={toastTraining}>
          {t(`${item.key}.learn`)}
          <LaunchIcon accessibility='decorative' />
        </Button>

        <Button onClick={complete} disabled={disableItemActions(item)}>
          {t(`${item.key}.confirm`)}
        </Button>
      </>
    )
  }
}

export const PayrollTraining = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      title={t(`${item.key}.title`)}
      content={
        <Content
          content={t(`${item.key}.content`)}
          alt={t(`${item.key}.alt`)}
        />
      }
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
