import { ApolloError, useQuery } from '@apollo/client'
import { FeinsDocument, FeinsQuery } from '@local/api/generated/gql/graphql'

export const useFeins = (): {
  data?: FeinsQuery['feins']
  loading: boolean
  error: ApolloError | undefined
} => {
  const { data, loading, error } = useQuery<FeinsQuery>(FeinsDocument)

  return { data: data?.feins.filter((item) => item.isActive), loading, error }
}
