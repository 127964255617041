import { ROUTES } from '../routes'
import {
  EmployeesIcon,
  IconProps,
  PayCheckIcon,
  PayrollEmployeeIcon,
  PayrollIcon,
  TaxIcon,
  ToastAccountIcon
} from '@toasttab/buffet-pui-icons'
import { ForwardRefExoticComponent, RefAttributes } from 'react'

// These enums hardcoded from backend because they aren't currently transmitted as an enum from graph.
// (Graph items just use strings.)

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  CANCELED = 'CANCELED',
  LOCKED = 'LOCKED'
}

export enum ChecklistItemKey {
  PAYROLL_ACTIVITY_GET_STARTED_BUTTON_CLICK = 'payroll-get-started-button-click',
  PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER = 'payroll-switcher-link-previous-provider',
  PAYROLL_PROFILE_COMPLETE_PAYROLL_PROFILE = 'payroll-complete-payroll-profile',
  PAYROLL_PROFILE_FEDERAL_I9 = 'payroll-profile-federal-i9',
  PAYROLL_PROFILE_FEDERAL_STATE_W4 = 'payroll-profile-federal-state-w4',
  PAYROLL_PROFILE_PERSONAL_INFO = 'payroll-profile-complete-personal-info',
  PAYROLL_PROFILE_SELECT_PAYMENT_METHOD = 'payroll-profile-select-payment-method',
  PAYROLL_TAX_SETUP_COMPLETE_TAX_SETUP = 'payroll-tax-setup-complete-tax-setup',
  PAYROLL_TAX_SETUP_CONFIRM_FEDERAL_TAX = 'payroll-tax-setup-confirm-federal-tax',
  PAYROLL_TAX_SETUP_SIGN_IRS_FORM_8655 = 'payroll-tax-setup-sign-irs-form-8655',
  PAYROLL_TAX_SETUP_CONFIRM_STATE_UNEMPLOYMENT_TAX = 'payroll-tax-setup-confirm-state-unemployment',
  PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX = 'payroll-tax-setup-confirm-state-withholding',
  PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX = 'payroll-tax-setup-link-toast-to-state-tax',
  PAYROLL_PAY_DEDUCTIONS_TIME_OFF = 'payroll-pay-deductions-time-off',
  PAYROLL_PAY_OFFER_TOAST_PAY_CARD = 'payroll-pay-offer-toast-pay-card',
  PAYROLL_PAY_PAYCHECK_DELIVERY_METHOD = 'payroll-pay-paycheck-delivery-method',
  PAYROLL_PAY_SET_UP_EMPLOYEE_PAY = 'payroll-pay-setup-employee-pay',
  PAYROLL_PAY_TIPS_SETTINGS = 'payroll-pay-tips-settings',
  PAYROLL_TEAM_ADD_YOUR_TEAM = 'payroll-team-add-team',
  PAYROLL_TEAM_EMPLOYEE_DOCS = 'payroll-team-employee-docs',
  PAYROLL_TEAM_EMPLOYEE_JOBS = 'payroll-team-employee-jobs',
  PAYROLL_TEAM_INVITE_TEAM = 'payroll-team-invite-team',
  PAYROLL_TEAM_SYNC_JOBS = 'payroll-team-sync-jobs',
  PAYROLL_BENEFITS = 'payroll-benefits',
  PAYROLL_BENEFITS_HEALTH = 'payroll-benefits-configure-health-insurance',
  PAYROLL_REVIEW_COMPLETE_TRAINING = 'payroll-review-complete-training',
  PAYROLL_REVIEW_CONFIRM_SETUP = 'payroll-review-confirm-setup',
  PAYROLL_REVIEW_MISSING_INFO = 'payroll-review-missing-info',
  PAYROLL_REVIEW_PAYROLL_SETUP = 'payroll-review-payroll-setup',
  PAYROLL_REVIEW_PREPARE_TO_RUN_PAYROLL = 'payroll-review-prepare-to-run-payroll'
}

export const CHECKLIST_URL_MAP: {
  [key: string]: {
    route?: string
    keepOpen?: boolean
    adminOnly?: boolean
    customerOnly?: boolean
  }
} = {
  [ChecklistItemKey.PAYROLL_PROFILE_PERSONAL_INFO]: {
    route: ROUTES.profile,
    customerOnly: true
  },
  [ChecklistItemKey.PAYROLL_PROFILE_FEDERAL_I9]: {
    route: `${ROUTES.i9Edit}?returnUrl=${ROUTES.dashboard}`,
    customerOnly: true
  },
  [ChecklistItemKey.PAYROLL_PROFILE_FEDERAL_STATE_W4]: {
    route: ROUTES.w4Landing,
    customerOnly: true
  },
  [ChecklistItemKey.PAYROLL_PROFILE_SELECT_PAYMENT_METHOD]: {
    route: ROUTES.paymentMethod,
    customerOnly: true
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_FEDERAL_TAX]: {
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_SIGN_IRS_FORM_8655]: {
    route: ROUTES.form8655
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX]: {
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_UNEMPLOYMENT_TAX]: {
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX]: {
    route: ROUTES.taxCenter,
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_PAY_PAYCHECK_DELIVERY_METHOD]: {
    route: ROUTES.deliveryMethod,
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_PAY_DEDUCTIONS_TIME_OFF]: {
    route: ROUTES.earnings,
    keepOpen: true,
    adminOnly: true
  },
  [ChecklistItemKey.PAYROLL_PAY_TIPS_SETTINGS]: {
    route: ROUTES.tips,
    keepOpen: true,
    adminOnly: true
  },
  [ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_DOCS]: {
    route: ROUTES.requiredDocumentation,
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_TEAM_SYNC_JOBS]: {
    route: ROUTES.syncJobs,
    keepOpen: true,
    adminOnly: true
  },
  [ChecklistItemKey.PAYROLL_TEAM_INVITE_TEAM]: {
    route: ROUTES.team,
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_REVIEW_MISSING_INFO]: {
    route: ROUTES.missingInfo,
    keepOpen: true
  },
  [ChecklistItemKey.PAYROLL_REVIEW_CONFIRM_SETUP]: {
    route: ROUTES.summary,
    keepOpen: true,
    // TODO can remove once company summary customer facing version is ready (in contrast to below, which is actually admin only)
    adminOnly: true
  },
  [ChecklistItemKey.PAYROLL_REVIEW_PREPARE_TO_RUN_PAYROLL]: {
    route: ROUTES.summary,
    keepOpen: true,
    adminOnly: true
  }
}

export const CHECKLIST_ICON_MAP: {
  [key: string]: ForwardRefExoticComponent<
    IconProps & RefAttributes<HTMLElement>
  >
} = {
  [ChecklistItemKey.PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER]: PayrollIcon,
  [ChecklistItemKey.PAYROLL_PROFILE_COMPLETE_PAYROLL_PROFILE]: ToastAccountIcon,
  [ChecklistItemKey.PAYROLL_TAX_SETUP_COMPLETE_TAX_SETUP]: TaxIcon,
  [ChecklistItemKey.PAYROLL_PAY_SET_UP_EMPLOYEE_PAY]: PayCheckIcon,
  [ChecklistItemKey.PAYROLL_TEAM_ADD_YOUR_TEAM]: EmployeesIcon,
  [ChecklistItemKey.PAYROLL_BENEFITS]: PayrollEmployeeIcon,
  [ChecklistItemKey.PAYROLL_REVIEW_PAYROLL_SETUP]: PayrollEmployeeIcon
}
