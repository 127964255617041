import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import '../styles.css'
import { ItemSubTask } from '../common/ItemSubTask'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import {
  ChecklistItemKey,
  TaskStatus as TaskStatusEnum
} from '../../models/checkListItemModels'
import { Item, TaskStatus } from '@local/api/generated/gql/graphql'
import { disableItemActions } from '../../utils/status'
import { useTranslation } from 'react-i18next'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'

import CelebrateGif from './setup-confirm-celebrate.gif'
import { useFirstPayroll } from '../../hooks/graph/useFirstPayroll'

const showCompleted = (isAdmin: boolean, status: TaskStatus) =>
  status === 'COMPLETED' && !isAdmin

const Content = ({
  isAdmin,
  item,
  isSwitcher
}: {
  isAdmin: boolean
  item: Item
  isSwitcher?: boolean
}) => {
  const { t } = useTranslation('ponc')
  const { dateFormatted: payrollDueDate } = useFirstPayroll()

  if (showCompleted(isAdmin, item.status)) {
    return (
      <div className='flex flex-col items-center'>
        <img
          src={CelebrateGif}
          alt={t(`${item.key}.completed.alt`)}
          // image has some built in padding
          className='w-full -mb-4'
        />
        <div className='mb-4 font-bold text-large'>
          {t(`${item.key}.completed.title`)}
        </div>
        <p className='mb-8 text-center'>{t(`${item.key}.completed.p1`)}</p>
        <p className='py-6 mb-4 text-center border-b-2'>
          {t(`${item.key}.completed.p2`, {
            dueDate: payrollDueDate
          })}
        </p>
        <Button
          as='a'
          variant='link'
          className='w-full'
          iconRight={<LaunchIcon accessibility='decorative' />}
          target='_blank'
          href='https://central.toasttab.com/s/article/Toast-Payroll-How-to-Run-Payroll'
        >
          {t(`${item.key}.completed.training`)}
        </Button>
      </div>
    )
  }

  return (
    <>
      <p>{isSwitcher ? t(`${item.key}.switcher`) : t(`${item.key}.summary`)}</p>
      <p>{t(`${item.key}.edit`)}</p>
    </>
  )
}

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_REVIEW_CONFIRM_SETUP,
      // TODO -- conflict between two task status types, can slowly remove the manual one
      TaskStatusEnum.COMPLETED
    )
    if (!showCompleted(isAdmin, 'COMPLETED')) closeFocus()
  }

  if (showCompleted(isAdmin, item.status)) {
    return <Button onClick={closeFocus}>{t('common.gotIt')}</Button>
  }

  return (
    <>
      <Button onClick={() => setChecklistOpen(false)} variant='secondary'>
        {t(`${item.key}.review`)}
      </Button>
      <Button onClick={complete} disabled={disableItemActions(item)}>
        {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
      </Button>
    </>
  )
}

export const PayrollSetup = ({
  isAdmin,
  closeFocus,
  item,
  isSwitcher
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
  isSwitcher?: boolean
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      title={
        showCompleted(isAdmin, item.status) ? '' : t(`${item.key}.confirm`)
      }
      content={
        <Content isAdmin={isAdmin} item={item} isSwitcher={isSwitcher} />
      }
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
