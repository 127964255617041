/* eslint-disable no-template-curly-in-string */
import { ChecklistItemKey } from '../../models/checkListItemModels'
import en from './en-US'
const es: typeof en = {
  checklistTitle: 'Prepárate para ejecutar tu primera nómina',
  checklistSubTitle:
    'Finalice la configuración de su nómina antes del {{payrollDate}}',
  businessSetup: 'Complete la configuración empresarial',
  welcome: {
    imgAlt: 'Un equipo de personas sonrientes y estilizadas',
    title: '¡Prepárate para pagarle a tu equipo!',
    firstCheck:
      '¿Todavía planeas pagarle a tu equipo antes del <0>{{date}}</0>? Háganos saber si desea cambiar la fecha de su primer cheque.',
    previousProvider: {
      question: '¿Su empresa le ha pagado a su equipo en {{año}}?',
      questionMulti:
        '¿Alguna de sus empresas le ha pagado a su equipo en {{año}}?',
      tooltip:
        'Háganos saber si su empresa pagó a sus empleados durante el año en que comenzó a utilizar Toast Payroll.',
      yes: 'Si',
      no: 'No'
    },
    businessInfo: {
      businesses: '{{count}} empresas',
      restaurants: '{{count}} restaurantes',
      locations: '{{count}} ubicaciones'
    },
    whatYouWillNeed: {
      title: 'Lo que necesitarás',
      items: {
        legal: 'Tu información legal y de perfil',
        deposit: 'Su información de depósito directo',
        federalTax: 'Documentos de impuestos federales comerciales',
        stateTax: 'Documentos fiscales estatales comerciales',
        onboarding: 'Documentos de incorporación del equipo',
        jobs: 'Trabajos en equipo y remuneración'
      }
    },
    getStarted: 'Comenzar'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_COMPLETE_PAYROLL_PROFILE]: {
    title: 'Perfil de nómina completo'
  },
  [ChecklistItemKey.PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER]: {
    title: 'Cambiar a Toast Payroll',
    stepTitle: 'Cambiar a Toast Payroll',
    description:
      'Debe enviar o ingresar información según cómo se cambie a Toast Payroll:',
    previousConditions: {
      paycheck: 'Miembros del equipo que reciben un cheque de pago',
      payrolls: 'Nóminas procesadas en el año calendario actual',
      taxes: 'Impuestos presentados en el año calendario actual'
    },
    previousProvider: {
      title: 'Proveedor de nómina anterior',
      text: 'Podemos iniciar sesión en su cuenta de nómina anterior para transferir la información necesaria para ayudarle a configurar. Envíenos un correo electrónico con su:',
      reqs: {
        currentProvider: 'Proveedor de nómina actual',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        phone: 'Número de teléfono para autenticación'
      }
    },
    accountant: {
      title: 'Contadora o tenedora de libros',
      p1: 'Vea toda la <0>información fiscal, salarial y de empleados</0> que necesita enviarnos.',
      p2: 'Si se cambia a Toast a principios de año, solo necesitamos la información que figura en Información del empleado.'
    },
    learnMore: 'Aprende más'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_PERSONAL_INFO]: {
    title: 'Complete su W4 federal y estatal'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_FEDERAL_I9]: {
    title: 'Complete su I-9 federal'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_FEDERAL_STATE_W4]: {
    title: 'Complete su W4 federal y estatal',
    tooltip: 'Complete su I-9 federal para completar su W4 federal y estatal'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_SELECT_PAYMENT_METHOD]: {
    title: 'Selecciona tu forma de pago'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_COMPLETE_TAX_SETUP]: {
    title: 'Configuración completa de impuestos'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_FEDERAL_TAX]: {
    title: 'Confirmar información de impuestos federales',
    stepTitle: 'Información de impuestos federales',
    description: `Usted proporcionó este número de identificación de empleador federal anteriormente durante el
    proceso de configuración. ¿Sigue estando vigente? Póngase en contacto con su incorporación
    consultor para hacer cambios si es necesario.`,
    name: 'Nombre legal de la empresa',
    error:
      'Comuníquese con su consultor de incorporación para proporcionar el nombre legal de su empresa faltante',
    fein: 'Número de identificación de empleador federal (FEIN)',
    businessInfo: 'Información comercial',
    locations: 'Ubicaciones',
    missingFein:
      'Comuníquese con su consultor de incorporación para proporcionar el número de identificación de su empleador federal faltante'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_SIGN_IRS_FORM_8655]: {
    title: 'Firme el formulario 8655 del IRS'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX]: {
    title: 'Confirmar la información de retención de impuestos del estado',
    stepTitle: 'Confirmar la información de retención de impuestos del estado',
    description:
      'Se le pidió que proporcionara información sobre la retención de impuestos estatales anteriormente durante el proceso de configuración. Es posible que esté sujeto a multas fiscales si procesa la nómina sin información fiscal precisa.',
    number: 'Número de cuenta de retención de impuestos estatales',
    numberMissing:
      'Comuníquese con su asesor de incorporación para proporcionarle el número de cuenta de retención estatal que falta.',
    frequency: 'Frecuencia de pago',
    type: 'Frecuencia de pago',
    frequencyMissing:
      'Comuníquese con su asesor de incorporación para informarle la frecuencia de pago de retención del estado que falta.',
    edit: 'Editar información de retención de impuestos',
    state: 'Estado',
    stateMultiLoc: 'Empresa y estado',
    taxAccount: 'Cuenta de impuestos',
    feinNumStates: '(1 estado)',
    feinNumStatesPlural: '({{count}} estados)'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_UNEMPLOYMENT_TAX]: {
    title: 'Confirmar información estatal de impuestos de desempleo',
    stepTitle: 'Confirmar información estatal de impuestos de desempleo',
    description:
      'Se le pidió que proporcionara información sobre el impuesto estatal de desempleo anteriormente durante el proceso de configuración. Es posible que esté sujeto a multas fiscales si procesa la nómina sin información fiscal precisa.',
    number: 'Número de cuenta del impuesto estatal de desempleo',
    numberMissing:
      'Comuníquese con su asesor de incorporación para proporcionarle el número de cuenta estatal de desempleo que le falta.',
    rate: 'Tasa del impuesto al desempleo',
    rateMissing:
      'Comuníquese con su asesor de incorporación para proporcionarle la tasa impositiva estatal de desempleo que le falta.',
    edit: 'Editar información del impuesto estatal de desempleo',
    type: 'Editar información del impuesto estatal de desempleo',
    state: 'Estado',
    stateMultiLoc: 'Empresa y estado',
    taxAccount: 'Cuenta de impuestos',
    feinNumStates: '(1 estado)',
    feinNumStatesPlural: '({{count}} estados)'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX]: {
    title: 'Vincular Toast a la cuenta de impuestos estatales',
    header: 'Vincule Toast a sus cuentas estatales',
    stateTaxAccess:
      'Para presentar y depositar impuestos trimestrales, configure cuentas de impuestos en línea con sus estados y vincule Toast Payroll como su administrador externo.',
    confirm: '¡Entiendo!',
    confirmTimeline:
      'Confirme que Toast esté vinculado a todas las agencias tributarias estatales antes de su próxima presentación trimestral.',
    stateWithholding: 'cuenta de retención de impuestos',
    unemployment: 'cuenta de impuestos de desempleo',
    pfml: 'Cuenta de licencia médica familiar remunerada',
    findState: 'Aprende a vincular Toast',
    guide: 'guía',
    state: 'Estado',
    taxAccount: 'Cuenta de impuestos',
    business: 'Empresa y estado',
    feinTaxAccount: '(1 cuenta)',
    feinTaxAccountPlural: '({{count}} cuentas)'
  },
  [ChecklistItemKey.PAYROLL_PAY_SET_UP_EMPLOYEE_PAY]: {
    title: 'Configurar el pago de los empleados'
  },
  [ChecklistItemKey.PAYROLL_PAY_PAYCHECK_DELIVERY_METHOD]: {
    title: 'Confirme el método de entrega de cheque de pago',
    description: 'Su método de entrega de cheque de pago está configurado para',
    description2:
      '. Cambie el método de entrega de su cheque de pago si desea seleccionar una opción diferente.',
    methods: 'Métodos de entrega de cheques de pago:',
    selfPrinted:
      'Imprimiré cheques por mi cuenta utilizando un archivo PDF proporcionado por Toast',
    selfWritten: 'Usaré mi propia chequera para imprimir y distribuir cheques',
    toastPrinted:
      'Pagaré para que Toast cree, imprima y envíe cheques a ubicaciones específicas ($20/paquete)',
    confirm: 'Confirmar método de entrega de cheque de pago',
    change: 'Cambiar método de entrega de cheque de pago'
  },
  [ChecklistItemKey.PAYROLL_PAY_OFFER_TOAST_PAY_CARD]: {
    title: 'Ofrezca Toast Pay Card y PayOut',
    stepTitle: 'Revise la tarjeta Toast Pay y PayOut',
    description:
      'Revise la configuración de su tarjeta Toast Pay y PayOut antes de que su equipo se incorpore a Toast. ¡Elimine la molestia de pagar propinas en efectivo y deshágase de esos cheques en papel!',
    imgText:
      'Dos Toast PayCards apiladas una encima de la otra de manera caprichosa',
    disclaimer: `Las tarjetas Toast Pay son emitidas por Sutton Bank, miembro de FDIC, conforme a la licencia de Mastercard®.
    Los Toast PayOuts están financiados por una línea de crédito del 0 % emitida a los empleadores por Toast, Inc. o WebBank,
    como se establece en el Acuerdo de Crédito del empleador. Toast y WebBank reservan cada uno el
    derecho a cambiar o descontinuar este programa en cualquier momento.`,
    info: 'Toast Pay Card y PayOut no están disponibles en todas las jurisdicciones.',
    later: 'Lo configuraré para una futura nómina',
    setup: 'Configúralo para la primera nómina'
  },
  [ChecklistItemKey.PAYROLL_PAY_DEDUCTIONS_TIME_OFF]: {
    title: 'Confirmar ganancias, deducciones y tiempo libre',
    description: `Su consultor de incorporación lo ayudará a configurar tipos de pago y tiempo libre
      políticas para garantizar que sus empleados estén configurados para recibir el pago con precisión.`,
    example: 'Ejemplos:',
    bonuses: 'Bonos',
    deductions: 'Deducciones de comida',
    garnishments: 'Embargos',
    pto: 'Tiempo libre pagado',
    leave: 'Baja por enfermedad',
    view: 'Ver ganancias'
  },
  [ChecklistItemKey.PAYROLL_PAY_TIPS_SETTINGS]: {
    title: 'Seleccione la configuración de propinas',
    select: 'Seleccione la configuración de propinas',
    description:
      'Su consultor de incorporación lo ayudará a configurar cómo desea calcular y pagar las propinas a los empleados de su restaurante.',
    types: 'Tipos de propinas:',
    pooled: {
      setup: 'Configuración',
      link: 'Gerente de propinas de Toast',
      title: 'Propinas combinadas',
      description:
        'para crear políticas personalizadas de agrupación de propinas'
    },
    normal: {
      title: 'Propinas normales',
      description:
        'Las propinas de cada empleado se sincronizarán directamente desde el POS a la nómina después de que marquen la salida.'
    },
    noTips: {
      title: 'Sin propinas',
      description:
        'Las propinas de cada empleado se ingresarán manualmente en Nómina'
    },
    close: 'Ver configuración de sugerencias'
  },
  [ChecklistItemKey.PAYROLL_TEAM_ADD_YOUR_TEAM]: {
    title: 'Agrega tu equipo'
  },
  [ChecklistItemKey.PAYROLL_BENEFITS]: {
    title: 'Beneficios de configuración'
  },
  [ChecklistItemKey.PAYROLL_BENEFITS_HEALTH]: {
    title: 'Seleccionar beneficios de salud',
    stepTitle: 'Configurar beneficios',
    description:
      'Hay varias formas de brindar beneficios, incluidos beneficios de salud, a sus empleados.',
    healthPlanDescription:
      'Con nuestro socio SimplyInsured, elija entre varios planes de salud, dentales y de visión',
    healthPlanLink: 'Encuentre un plan de salud',
    deductionDescription: 'Agregar deducciones manualmente',
    deductionLink: 'Configurar una deducción',
    confirm: 'he hecho esto'
  },
  [ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_DOCS]: {
    title: 'Agregar documentos de empleados',
    stepTitle: 'Agregar documentos de incorporación de empleados',
    add: 'Agregar documentos',
    confirm: 'He añadido todos los documentos.',
    content: `Puede agregar documentos opcionales para que los nuevos empleados los revisen cuando se unan a su restaurante. Háganos saber cuando haya terminado.`,
    example: 'Ejemplos de documentos:',
    documents: {
      handbook: 'Manuales de empleados',
      business: 'Políticas comerciales',
      forms: 'Formularios específicos del estado'
    }
  },
  [ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_JOBS]: {
    title: 'Confirmar trabajos de empleados',
    confirm: 'Confirmar trabajos',
    add: 'Agregar trabajos',
    content:
      'Esta es la lista de trabajos que proporcionó anteriormente en el proceso de configuración. ¿Sigue estado vigente?',
    subtitle: 'Empleos por ubicación',
    jobs: 'trabajos',
    job: 'trabajo'
  },
  [ChecklistItemKey.PAYROLL_TEAM_SYNC_JOBS]: {
    title: 'Sincronizar trabajos de empleados',
    content:
      'Su consultor de incorporación está revisando los trabajos de empleados que ingresó para confirmar que están configurados correctamente para el procesamiento de nómina.',
    close: 'Ver asignaciones de trabajos',
    tooltip:
      'Confirme todos los trabajos en su empresa para que los revise su consultor de incorporación'
  },
  [ChecklistItemKey.PAYROLL_TEAM_INVITE_TEAM]: {
    title: 'Añade o revisa tu equipo',
    content: `Agregue o revise todos los miembros del equipo a los que le gustaría pagar su primera nómina antes del {{dueDate}}.`,
    content2: 'Asegúrese de que cada miembro del equipo tenga:',
    requirements: {
      social: 'Número de seguro social',
      assigned: 'Trabajo asignado y salario.',
      method: 'Método de pago',
      wtl: 'Ubicación del impuesto de trabajo',
      link: 'Revisa los métodos de pago de tu equipo'
    },
    confirm: 'Confirmar equipo',
    add: 'Añade o revisa tu equipo'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_PAYROLL_SETUP]: {
    title: 'Revisar la configuración de la nómina'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_COMPLETE_TRAINING]: {
    title: 'Entrenamiento completo de nómina',
    content: `Hemos seleccionado videos de capacitación para ayudarlo a aprender cómo procesar la nómina
      y gestiona tu equipo. Recomendamos verlos antes de ejecutar su
      primera nómina.`,
    imgText:
      'Una imagen de muestra de un video de capacitación para procesar la nómina',
    learn: 'Aprende a usar Toast Nómina ',
    confirm: 'He visto el material de formación.'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_MISSING_INFO]: {
    title: 'Confirmar configuración de impuestos',
    header: 'Confirmar configuración de impuestos',
    confirm: 'He revisado la información que falta',
    check:
      'Verifique que no le falte información crítica de la empresa o de los empleados. Esto ayudará a garantizar la precisión cuando vaya a pagar a su equipo por primera vez.',
    review: 'Revisar la información que falta'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_CONFIRM_SETUP]: {
    title: 'Confirmar configuración de nómina',
    review: 'Revisar la configuración de la nómina',
    confirm: 'Confirmar configuración de nómina',
    summary:
      'Aquí hay un resumen de toda su configuración de nómina. Por favor, revíselo para ver si es exacto.',
    switcher:
      'Hemos cargado información de nómina de su proveedor de nómina anterior en Toast. Aquí hay un resumen de toda la configuración de su nómina. Revíselo para comprobar su exactitud y vuelva aquí para confirmarlo.',
    edit: 'Si necesita hacer cambios, edite su configuración de nómina o comuníquese con su consultor de incorporación para obtener ayuda.',
    completed: {
      alt: 'Una explosión de confeti de celebración',
      title: '¡Estás listo para procesar la nómina!',
      p1: '¡Gran trabajo! Está casi listo para ejecutar su primera nómina. Toast Payroll hace que pagar a tus empleados sea rápido y fácil, para que puedas usar tu tiempo haciendo lo que amas.',
      p2: 'Su asesor de incorporación está realizando una verificación final de precisión para asegurarse de que esté ejecutando correctamente su primera nómina el {{dueDate}}.',
      training: 'Aprenda a ejecutar la nómina'
    },
    tooltip:
      'Agregue o revise su equipo y la información comercial que falta para confirmar la configuración de la nómina'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_PREPARE_TO_RUN_PAYROLL]: {
    title: 'Revisión de la configuración de nómina de Toast',
    content: `Su consultor de incorporación está revisando todos sus negocios, impuestos y empleados.
    configuración de pago para pagar a sus empleados con precisión.`,
    review: 'Revisar la configuración de la nómina',
    tooltip:
      'Confirme la configuración de la nómina para que su asesor de incorporación revise todas las configuraciones de la nómina'
  },
  common: {
    complete: 'Marcar completo',
    completedByOC: 'completado por su consultora de incorporación',
    back: 'Volver a la lista de verificación',
    done: '¡Ya has terminado!',
    next: 'Próximo',
    gotIt: 'Entiendo',
    confirm: 'Confirmar',
    loader: 'Cargando configuración de nómina',
    error: 'Lo sentimos, no podemos cargar su configuración de nómina',
    notProvided: 'NO PROVISTO',
    appliedFor: 'APLICADO'
  }
}

export default es
