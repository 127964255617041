import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useCompanyCode } from '@toasttab/ec-session'

import { Item } from '@local/api/generated/gql/graphql'

import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { disableItemActions } from '../../utils/status'
import { ItemSubTask } from '../common/ItemSubTask'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'

type HealthBenefitsProps = {
  isAdmin: boolean
  item: Item
  onClose: VoidFunction
}

export const HealthBenefits: React.FunctionComponent<HealthBenefitsProps> = ({
  isAdmin,
  item,
  onClose
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      title={t(`${item.key}.stepTitle`)}
      content={<Content item={item} />}
      footer={<Footer isAdmin={isAdmin} onClose={onClose} item={item} />}
    />
  )
}

type ContentProps = {
  item: Pick<Item, 'key'>
}

const Content: React.FunctionComponent<ContentProps> = ({ item }) => {
  const { t } = useTranslation('ponc')
  const companyCode = useCompanyCode()

  return (
    <div className='grid gap-y-2'>
      <p className='mb-0'>{t(`${item.key}.description`)}</p>
      <ul className='ml-5 divide-y'>
        <ListItem
          label={t(`${item.key}.healthPlanDescription`)}
          link={{
            trackId: '',
            label: t(`${item.key}.healthPlanLink`),
            href: `/${companyCode}/benefits/group-health/quote`
          }}
        />
        <ListItem
          label={t(`${item.key}.deductionDescription`)}
          link={{
            trackId: '',
            label: t(`${item.key}.deductionLink`),
            href: `/${companyCode}/benefits/add-deduction`
          }}
        />
      </ul>
    </div>
  )
}

type ListItemProps = {
  label: string
  link?: {
    label: string
    href: string
    trackId?: string
  }
}

const ListItem: React.FunctionComponent<ListItemProps> = ({ label, link }) => {
  return (
    <li className='py-4'>
      {label}
      {link && (
        <Button
          data-toast-track-id={link.trackId}
          as='a'
          href={link.href}
          iconRight={
            <ArrowForwardIcon accessibility='decorative' className='pb-0' />
          }
          variant='text-link'
        >
          {link.label}
        </Button>
      )}
    </li>
  )
}

type FooterProps = {
  isAdmin: boolean
  item: Pick<Item, 'key' | 'status'>
  onClose: VoidFunction
}

const Footer: React.FunctionComponent<FooterProps> = ({
  isAdmin,
  item,
  onClose
}) => {
  const { t } = useTranslation('ponc')
  const { updateTask } = useUpdateTask()

  const complete = () => {
    updateTask(ChecklistItemKey.PAYROLL_BENEFITS_HEALTH, TaskStatus.COMPLETED)
    onClose()
  }

  return (
    <Button disabled={disableItemActions(item)} onClick={complete}>
      {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
    </Button>
  )
}
