import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { useApollo } from '@local/api/client'
import { I18nProvider } from '../../packages/app-checklist/contexts/i18n'

type Props = {
  children: React.ReactNode
}

export const AppProviders = ({ children }: Props) => {
  const { client } = useApollo()
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <I18nProvider>{children}</I18nProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}
