import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ItemSubTask } from '../common/ItemSubTask'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { useFeins } from '../../hooks/graph/useFeins'
import { FeinsQuery, Item } from '@local/api/generated/gql/graphql'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../common/loader'
import { Error } from '../../common/error'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { disableItemActions } from '../../utils/status'
import {
  feinTPAAccounts,
  stateTPAAccounts,
  capitalize
} from '../../checklistHelper'

export const LinkStateTax = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  item: Item
  closeFocus: () => void
}) => {
  const { t } = useTranslation('ponc')
  const { isLoading, updateTask } = useUpdateTask()

  const { data, loading, error } = useFeins()
  const feins = data && data.length ? data : undefined
  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  if (loading) return <Loader />

  if (error || !feins) return <Error />
  return (
    <ItemSubTask
      title={t(`${item.key}.header`)}
      content={
        <div className='flex flex-col space-y-6'>
          <p>{t(`${item.key}.stateTaxAccess`)}</p>
          <p>{t(`${item.key}.confirmTimeline`)}</p>
          <StateTaxContent item={item} feins={feins} />
        </div>
      }
      footer={
        <>
          <Button
            as='a'
            variant='secondary'
            className='w-full'
            iconRight={<LaunchIcon accessibility='decorative' />}
            target='_blank'
            href='https://central.toasttab.com/s/article/Toast-Payroll-State-Third-Party-Administrator-Linking'
          >
            {t(`${item.key}.findState`)}
          </Button>
          <Button
            disabled={isLoading || disableItemActions(item)}
            className='w-full'
            onClick={complete}
          >
            {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
          </Button>
        </>
      }
    />
  )
}

const StateTaxContent = ({
  item,
  feins
}: {
  item: Item
  feins?: FeinsQuery['feins']
}) => {
  if (!feins) {
    return null
  }

  const isMultiLocation = feins.length > 1

  return (
    // refer to index.css for styles affecting these components
    <div className='state-tax'>
      {!isMultiLocation && <SingleLocLayout fein={feins[0]} item={item} />}
      {isMultiLocation && <MultiLocLayout feins={feins} item={item} />}
    </div>
  )
}

const MultiLocLayout = ({
  item,
  feins
}: {
  item: Item
  feins: FeinsQuery['feins']
}) => {
  const { t } = useTranslation('ponc')
  return (
    <>
      <Table className='state-table'>
        <Head>
          <Row>
            <HeadingCell>{t(`${item.key}.business`)}</HeadingCell>
            <HeadingCell className='text-right'>
              {t(`${item.key}.taxAccount`)}
            </HeadingCell>
          </Row>
        </Head>
      </Table>
      <FeinInfoTable item={item} feins={feins} />
    </>
  )
}

const FeinInfoTable = ({
  item,
  feins
}: {
  item: Item
  feins: FeinsQuery['feins']
}) => {
  const tpaAccounts = feins.map((fein) => ({
    feinName: fein.name,
    feinId: fein.id,
    accounts: feinTPAAccounts(fein, item)
  }))
  const feinAccounts = tpaAccounts.filter((fein) => fein.accounts.length > 0)
  const { t } = useTranslation('ponc')
  if (feinAccounts.length > 0) {
    return (
      <>
        {feinAccounts.map((fein) => {
          return (
            <AccordionItem
              title={`${fein.feinName} ${t(
                `${item.key}.feinTaxAccount${
                  fein.accounts.length > 1 ? 'Plural' : ''
                }`,
                { count: fein.accounts.length }
              )}`}
              id={`AccordionItem-${fein.feinId}`}
              key={`AccordionItem-${fein.feinId}`}
              initialIsOpen={feinAccounts.length === 1}
            >
              <Table className='ponc-table-row' key={`table-${fein.feinId}`}>
                <Body>
                  {fein.accounts.map((account) => (
                    <StateTaxRow account={account} key={account[1]} />
                  ))}
                </Body>
              </Table>
            </AccordionItem>
          )
        })}
      </>
    )
  }
}

const SingleLocLayout = ({
  item,
  fein
}: {
  item: Item
  fein: FeinsQuery['feins'][0]
}) => {
  const { t } = useTranslation('ponc')
  return (
    <>
      <Table className='state-table'>
        <Head>
          <Row>
            <HeadingCell>{t(`${item.key}.state`)}</HeadingCell>
            <HeadingCell className='text-right'>
              {t(`${item.key}.taxAccount`)}
            </HeadingCell>
          </Row>
        </Head>
      </Table>
      <StateInfoTable item={item} states={fein.states} />
    </>
  )
}
const StateInfoTable = ({
  item,
  states
}: {
  item: Item
  states: FeinsQuery['feins'][0]['states']
}) => {
  const tpaAccounts = states.map((state) => ({
    stateName: state.name,
    accounts: stateTPAAccounts(state, item)
  }))
  const stateAccounts = tpaAccounts.filter((state) => state.accounts.length > 0)
  const { t } = useTranslation('ponc')
  if (stateAccounts.length > 0) {
    return (
      <>
        {stateAccounts.map((state) => {
          return (
            <AccordionItem
              title={`${state.stateName} ${t(
                `${item.key}.feinTaxAccount${
                  state.accounts.length > 1 ? 'Plural' : ''
                }`,
                { count: state.accounts.length }
              )}`}
              id={`AccordionItem-${state.stateName}`}
              key={`AccordionItem-${state.stateName}`}
              initialIsOpen={stateAccounts.length === 1}
            >
              <Table
                className='ponc-table-row'
                key={`table-${state.stateName}`}
              >
                <Body>
                  {state.accounts.map((account) => (
                    <TaxRow account={account} key={account} />
                  ))}
                </Body>
              </Table>
            </AccordionItem>
          )
        })}
      </>
    )
  }
}

const StateTaxRow = ({ account }: { account: [string, string] }) => {
  const { t } = useTranslation('ponc')
  return (
    <Row>
      <Cell>{`${account[0]} ${t(account[1])}`}</Cell>
    </Row>
  )
}

const TaxRow = ({ account }: { account: string }) => {
  const { t } = useTranslation('ponc')
  return (
    <Row>
      <Cell>{capitalize(t(account))}</Cell>
    </Row>
  )
}
