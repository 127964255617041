import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import '../styles.css'
import { ItemSubTask } from '../common/ItemSubTask'
import { useFirstPayroll } from '../../hooks/graph/useFirstPayroll'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { disableItemActions } from '../../utils/status'
import { Item } from '@local/api/generated/gql/graphql'
import { useTranslation } from 'react-i18next'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { useCompanyCode } from '@toasttab/ec-session'
import { useBulkActionDirectDepositLink } from '../../models/featureFlag'

const Content = ({ item }: { item: Item }) => {
  const { dateFormatted: payrollDueDate, loading } = useFirstPayroll()
  const { t } = useTranslation('ponc')
  const useBulkDirectDepositLink = useBulkActionDirectDepositLink()
  const companyCode = useCompanyCode()

  if (loading) return <MerryGoRound />

  return (
    <>
      <p className='mb-6'>
        {t(`${item.key}.content`, { dueDate: payrollDueDate })}
      </p>
      <p className='mb-4'>{t(`${item.key}.content2`)}</p>
      <ul className='pb-4 ml-5 space-y-2 divide-y'>
        <li className='py-4'>{t(`${item.key}.requirements.social`)}</li>
        <li className='py-4'>{t(`${item.key}.requirements.assigned`)}</li>
        {useBulkDirectDepositLink ? (
          <li className='pt-4 pb-1'>
            {t(`${item.key}.requirements.method`)}
            <Button
              data-toast-track-id='bulk-action-direct-deposit-onboarding-link'
              variant='text-link'
              as={'a'}
              href={`/mvc/${companyCode}/Company/Setup/DirectDepositBulkAction`}
              iconRight={
                <ArrowForwardIcon accessibility='decorative' className='pb-0' />
              }
            >
              {t(`${item.key}.requirements.link`)}
            </Button>
          </li>
        ) : (
          <li className='py-4'>{t(`${item.key}.requirements.method`)} </li>
        )}
        <li className='py-4'>{t(`${item.key}.requirements.wtl`)}</li>
      </ul>
    </>
  )
}

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  const complete = () => {
    updateTask(ChecklistItemKey.PAYROLL_TEAM_INVITE_TEAM, TaskStatus.COMPLETED)
    closeFocus()
  }

  return (
    <>
      <Button variant='secondary' onClick={() => setChecklistOpen(false)}>
        {t(`${item.key}.add`)}
      </Button>
      <Button onClick={complete} disabled={disableItemActions(item)}>
        {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
      </Button>
    </>
  )
}

export const AddInvite = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      title={t(`${item.key}.title`)}
      content={<Content item={item} />}
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
