import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from '@apollo/client'
import { StrictTypedTypePolicies } from '@local/api/generated/helpers'
import { useSentry } from 'banquet-runtime-modules'
import { onError } from '@apollo/client/link/error'

export const useApollo = () => {
  const { captureException } = useSentry()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        captureException(
          new Error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      })
    }
    if (networkError) {
      captureException(new Error(`[Network error]: ${networkError}`))
    }
  })

  const httpLink = new HttpLink({
    uri: '/graphql'
  })

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Item: {
          keyFields: ['key']
        },
        ChecklistState: {
          keyFields: ['customerUuid']
        }
      } as StrictTypedTypePolicies
    }),
    uri: '/graphql'
  })

  return { client }
}
