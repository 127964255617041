import * as React from 'react'
import { generatePath, useLocation } from 'react-router-dom'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'
import { FullPageOverlayPortal } from './FullPageOverlay'
import { useResync } from './hooks/graph/useResync'
import {
  Checklist,
  ChecklistContent as ChecklistContentType,
  ChecklistItemStatus,
  ItemInfo
} from '@toasttab/buffet-patterns-onboarding-checklist'
import { Item } from '@local/api/generated/gql/graphql'
import { FocusContent } from './sections'
import {
  CHECKLIST_ICON_MAP,
  CHECKLIST_URL_MAP,
  ChecklistItemKey,
  TaskStatus
} from './models/checkListItemModels'
import {
  calculateProgress,
  findItem,
  getCheckListIcon,
  getProgressLabel,
  mapTaskStatusToChecklistStatus,
  getLockedIcon,
  updateRoute
} from './checklistHelper'
import { useCompanyCode, useUser } from '@toasttab/ec-session'
import cx from 'classnames'
import { Loader } from './common/loader'
import {
  useCompanySummary,
  useCompanySummaryV3,
  usePonc,
  useGAView,
  useChecklistReshowTimer
} from './models/featureFlag'
import { CheckCircleSelectedFillIcon } from '@toasttab/buffet-pui-icons'
import { useEcProps } from '@toasttab/ec-banquet-props'

import { useFirstPayroll } from './hooks/graph/useFirstPayroll'
import { useTranslation } from 'react-i18next'
import enUS from './shared/localization/en-US'
import es from './shared/localization/es'
import { ChecklistError, Error, ErrorBoundary } from './common/error'
import { useChecklistState } from './hooks/graph/useChecklistState'
import { useApollo } from '@local/api/client'
import './sections/styles.css'
import { useCustomerInfo } from './hooks/graph/useCustomerInfo'
import { useOnboardingPermissions } from './hooks/graph/useOnboardingPermissions'
import { useLocalStorageState } from './utils/storage'
import { useFeins } from './hooks/graph/useFeins'

const NAV_HEIGHT = '64px'

const useChecklist = ({
  setChecklistOpen,
  setSection
}: {
  setChecklistOpen: (open: boolean) => void
  setSection: (key: string) => void
}) => {
  const { t, i18n } = useTranslation('ponc')
  const enableCompanySummary = useCompanySummary()
  const enableCompanySummaryV3 = useCompanySummaryV3()
  const { employeeUuid, isUserSuperAdmin, isUserSuperAdminLight } = useUser()
  const companyCode = useCompanyCode()
  const isAdmin = isUserSuperAdmin || isUserSuperAdminLight
  const { ecNavigate: navigate } = useEcProps()
  const { data: feins } = useFeins()

  const onClickItem = (item: Item) => {
    const { route, keepOpen, adminOnly, customerOnly } =
      CHECKLIST_URL_MAP[item.key] || {}

    const customerHasCompanySummaryAccess =
      enableCompanySummary &&
      enableCompanySummaryV3 &&
      item.key === ChecklistItemKey.PAYROLL_REVIEW_CONFIRM_SETUP

    const hasAccessToRoute =
      ((!adminOnly || isAdmin) && (!customerOnly || !isAdmin)) ||
      customerHasCompanySummaryAccess

    if (route && hasAccessToRoute) {
      const route = updateRoute(
        item,
        feins?.map((a) => a.id)
      )
      if (!keepOpen) {
        setChecklistOpen(false)
      }

      const pathParams = {
        companyCode,
        employeeId: employeeUuid
      }

      navigate(generatePath(route, pathParams))
    }

    // current design equates route + keepOpen with actually _having_ focused view content
    // if that design changes, then will need to add a hasContent prop to the URL map and check that instead
    if (!route || keepOpen) {
      setSection(item.key)
    }
  }

  const mapSubItem = (item: Item): ItemInfo => ({
    lockedIconClass: item.status === 'COMPLETED' ? 'text-success' : undefined,
    lockedIcon: getLockedIcon(
      item.status,
      isAdmin,
      CHECKLIST_URL_MAP[item.key]?.customerOnly
    ),
    incompleteIcon: getCheckListIcon(item.assignedTo, isAdmin),
    status: mapTaskStatusToChecklistStatus(
      item.status,
      isAdmin,
      CHECKLIST_URL_MAP[item.key]?.customerOnly
    ),
    title: t(`${item.key}.title`),
    tooltipMessage: i18n.exists(`ponc:${item.key}.tooltip`)
      ? t(`${item.key}.tooltip`)
      : undefined,
    onClick: () => onClickItem(item)
  })

  return {
    onClickItem,
    mapTaskItemToChecklistItem: (item: Item): ChecklistContentType[0] =>
      item.subItems?.length
        ? {
            incompleteIcon: CHECKLIST_ICON_MAP[item.key],
            title: `${t(`${item.key}.title`)}${getProgressLabel(item)}`,
            status: item.status as ChecklistItemStatus,
            items: item.subItems.map(mapSubItem)
          }
        : mapSubItem(item)
  }
}

const ChecklistContent = () => {
  const { client } = useApollo()
  const { isUserSuperAdmin, isUserSuperAdminLight } = useUser()

  const { resync, loading: resyncing, error } = useResync()
  const { items, itemsDisplay } = useChecklistState()

  // loadedInitial tracking helps avoid flicker as PONC displays, then enters loading state
  const [loadedInitial, setLoadedInitial] = React.useState(false)
  const [isChecklistOpen, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )
  const [section, setSection] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-focused-section'
  )
  const { t } = useTranslation('ponc')

  const { onClickItem, mapTaskItemToChecklistItem } = useChecklist({
    setChecklistOpen,
    setSection
  })

  const enableGAView = useGAView()

  const isAdmin = isUserSuperAdmin || isUserSuperAdminLight

  const { dateFormatted: firstPayrollDate } = useFirstPayroll()
  const { data: customerInfo, loading: loadingCustomerInfo } = useCustomerInfo()
  const isSwitcher =
    !!customerInfo?.customerSettings.HAS_PREVIOUS_PAYROLL_PROVIDER

  React.useEffect(() => {
    const reset = async () => {
      // This guarantees that all queries for specific tasks will re-run
      // In the future, a less wasteful solution would be to only re-run specific queries we know need re-running, but that will depend on websockets
      await client.resetStore()
      await resync()
      setLoadedInitial(true)
    }

    if (isChecklistOpen) reset()
    else setLoadedInitial(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecklistOpen, resync])

  const welcomeScreenItem = items?.find(
    ({ key }) =>
      key === ChecklistItemKey.PAYROLL_ACTIVITY_GET_STARTED_BUTTON_CLICK
  )
  const showWelcomeScreen =
    enableGAView && welcomeScreenItem?.status !== 'COMPLETED'

  const selectedItem = showWelcomeScreen
    ? welcomeScreenItem
    : findItem(itemsDisplay, ({ key }) => key === section)

  let firstIncompleteItem = itemsDisplay?.find(
    ({ status }) => status !== TaskStatus.COMPLETED
  )
  // keep recursing, but only if there are more child items in the most recently found incomplete
  while (firstIncompleteItem?.subItems?.length) {
    const firstIncompleteSubItem = firstIncompleteItem.subItems?.find(
      ({ status }) => status !== TaskStatus.COMPLETED
    )
    if (firstIncompleteSubItem) firstIncompleteItem = firstIncompleteSubItem
    else break // avoid infinite loop if there are subitems but none are incomplete
  }

  const loading =
    !loadedInitial || loadingCustomerInfo || resyncing || !itemsDisplay

  const caption = loading
    ? t('common.loader')
    : selectedItem
    ? t('common.back')
    : firstIncompleteItem
    ? `${t('common.next')}: ${t(`${firstIncompleteItem.key}.title`)}`
    : t('common.done')

  return (
    <>
      <div
        // z-index MUST be 0 due to a bug with tooltips and portaling
        // ideally it would be at z-30 as part of our standards, but currently works at 0
        // if it must be raised, confirm that tooltips still work
        className={cx('fixed inset-0 z-0', {
          'bg-darken-56': !!section || showWelcomeScreen,
          'pointer-events-none': !(section || showWelcomeScreen)
        })}
        style={{
          top: NAV_HEIGHT
        }}
      />
      <div
        className='fixed right-0 z-0'
        style={{
          top: NAV_HEIGHT,
          width: '380px',
          height: `calc(100vh - ${NAV_HEIGHT})`,
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.09), 0px 8px 19px 1px rgba(0, 0, 0, 0.06), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)'
        }}
      >
        <Checklist
          testId='payroll-checklist'
          headerProps={{
            title: t('checklistTitle'),
            subtitle: t('checklistSubTitle', {
              payrollDate: firstPayrollDate
            }),
            onClickClose: () => setChecklistOpen(false),
            progressFraction: itemsDisplay
              ? calculateProgress(itemsDisplay)
              : 0,
            caption,
            onClickCaption: () => {
              if (selectedItem) {
                setSection(null)
              } else if (firstIncompleteItem) {
                onClickItem(firstIncompleteItem)
              }
            }
          }}
          hideHeader={showWelcomeScreen}
          items={
            itemsDisplay
              ? [
                  {
                    title: t('businessSetup'),
                    status: ChecklistItemStatus.LOCKED,
                    lockedIconClass: 'text-success',
                    lockedIcon: CheckCircleSelectedFillIcon
                  },
                  ...itemsDisplay.map(mapTaskItemToChecklistItem)
                ]
              : []
          }
          contentAlt={
            error ? (
              <Error />
            ) : loading ? (
              <Loader />
            ) : selectedItem ? (
              <FocusContent
                item={selectedItem}
                isAdmin={isAdmin}
                closeFocus={() => setSection(null)}
                isSwitcher={isSwitcher}
              />
            ) : undefined
          }
        />
      </div>
    </>
  )
}

const ChecklistDataWrapper = () => {
  const {
    i18n: { addResourceBundle }
  } = useTranslation()
  addResourceBundle('en-US', 'ponc', enUS)
  addResourceBundle('es', 'ponc', es)
  const companyCode = useCompanyCode()

  const enablePonc = usePonc()
  // unique case for the `toast` customer, which cannot finish onboarding
  // but still default displays the checklist
  const notDebugCompany = companyCode !== 'toast'
  const checklistReshowTimer = useChecklistReshowTimer()
  const [lastChecklistDefaultedOpen, setLastChecklistDefaultedOpen] =
    useLocalStorageState('last-defaulted-open')

  const [checklistSession, setChecklistSession] =
    useEcCompanySessionStorageState('ec-payroll-onboarding-checklist-is-open')

  const { data: permissions } = useOnboardingPermissions()

  const location = useLocation()

  const canShowChecklist =
    enablePonc &&
    notDebugCompany &&
    permissions?.payrollOnboardingPermissions.includes(
      'EDIT_PAYROLL_ONBOARDING'
    ) &&
    permissions?.payrollOnboardingPermissions.includes(
      'VIEW_PAYROLL_ONBOARDING'
    )
  const showChecklist = checklistSession && canShowChecklist

  React.useEffect(() => {
    // homepage of payroll
    if (location.pathname.match(/.*\/dashboard$/) && canShowChecklist) {
      const currentTimeEpoch = new Date().getTime()
      if (
        !lastChecklistDefaultedOpen ||
        currentTimeEpoch - lastChecklistDefaultedOpen >
          // timer is in minutes -- convert to milliseconds (unix epoch)
          checklistReshowTimer * 60 * 1000
      ) {
        setLastChecklistDefaultedOpen(currentTimeEpoch)
        setChecklistSession(true)
      }
    }
    // Only trigger when the conditions for showing checklist (location, permissions) change --
    // don't want to unnecessarily force logic/opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowChecklist, location])

  return (
    <FullPageOverlayPortal>
      <ErrorBoundary fallback={<ChecklistError />}>
        {showChecklist ? <ChecklistContent /> : null}
      </ErrorBoundary>
    </FullPageOverlayPortal>
  )
}

const PayrollOnboardingChecklist = () => (
  // Need an extra error boundary here to avoid error states leaking out into the wider Payroll page,
  // since this is above the Overlay portal
  <ErrorBoundary fallback={null}>
    <ChecklistDataWrapper />
  </ErrorBoundary>
)

export default PayrollOnboardingChecklist
