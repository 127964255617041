import { ApolloError, useQuery } from '@apollo/client'
import { FindJobsByLocationQuery } from '@local/api/documents'
import { CustomerConfigurationQuery } from '@local/api/generated/gql/graphql'

export const useJobsByLocation = (): {
  data?: CustomerConfigurationQuery
  loading: boolean
  error: ApolloError | undefined
} => {
  const { data, loading, error } = useQuery<CustomerConfigurationQuery>(
    FindJobsByLocationQuery
  )

  return { data, loading, error }
}
