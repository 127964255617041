import { useQuery } from '@apollo/client'
import { upcomingPayrolls } from '@local/api/documents'
import { UpcomingQuery } from '@local/api/generated/gql/graphql'
import { SpaDate } from '../../utils/SpaDate'
import { useTranslation } from 'react-i18next'

export const useFirstPayroll = (): {
  date?: SpaDate
  dateFormatted: string
  loading: boolean
} => {
  const { i18n } = useTranslation()
  const { data, loading } = useQuery<UpcomingQuery>(upcomingPayrolls)
  const upcoming = data?.upcoming
  let firstPayrollDate =
    upcoming && upcoming.length > 0
      ? SpaDate.fromISOString(upcoming[0].payDate)
      : undefined
  if (firstPayrollDate && upcoming && upcoming?.length > 1) {
    firstPayrollDate = upcoming
      .map((it) => SpaDate.fromISOString(it.payDate))
      .reduce(
        (minDate, currentDate) =>
          currentDate < minDate ? currentDate : minDate,
        firstPayrollDate
      )
  }

  return {
    date: firstPayrollDate,
    dateFormatted:
      firstPayrollDate?.toFormat(
        {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        },
        i18n.language
      ) ?? 'N/A',
    loading
  }
}
