import { useMutation } from '@apollo/client'
import {
  ChecklistState,
  UpdateTaskDocument,
  UpdateTaskMutation
} from '@local/api/generated/gql/graphql'
import { TaskStatus } from '../../../models/checkListItemModels'
import { findItem } from '../../../checklistHelper'
import { useChecklistState } from '../useChecklistState'

const optimisticData = (
  checklistState: ChecklistState,
  taskKey: string,
  status: TaskStatus
): UpdateTaskMutation => {
  // clone state to avoid modifying cache directly by accident
  const newState = {
    updateTask: {
      ...checklistState,
      items: checklistState.items.map((item) => ({
        ...item,
        subItems: item.subItems.map((subItem) => ({ ...subItem }))
      }))
    }
  }

  const matchingItem = findItem(
    newState.updateTask?.items,
    (item) => item.key === taskKey
  )
  // only way to not find the item is if code supplies a typo item key, but it is possible
  if (matchingItem) {
    // modifying directly rather than cloning is not ideal, but this allows easily re-using existing findItem helper method
    matchingItem.status = status
  }

  return newState
}

export const useUpdateTask = () => {
  const currentData = useChecklistState()
  const [updateTask, { error, loading: isLoading }] =
    useMutation(UpdateTaskDocument)

  return {
    isLoading,
    error,
    updateTask: (taskKey: string, status: TaskStatus) => {
      updateTask({
        variables: { taskKey, status },
        optimisticResponse: !currentData
          ? undefined
          : optimisticData(currentData, taskKey, status)
      })
    }
  }
}
