/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation ChecklistItems {\n    checklistState: resyncTasks {\n      customerUuid\n      items {\n        # change these to fragments if the list of item data grows\n        key\n        status\n        title\n        assignedTo\n        subItems {\n          key\n          status\n          title\n          assignedTo\n        }\n      }\n    }\n  }\n": types.ChecklistItemsDocument,
    "\n  query PaycheckDeliveryMethod {\n    paycheckDeliverySettings {\n      method\n      needsPdfFile\n      usingToastCheckStock\n    }\n  }\n": types.PaycheckDeliveryMethodDocument,
    "\n  query Jobs($parentLevelUuid: String) {\n    jobs(parentLevelUuid: $parentLevelUuid) {\n      uuid\n      name\n      isOrphan\n    }\n  }\n": types.JobsDocument,
    "\n  query CustomerConfiguration {\n    customerConfiguration {\n      locations {\n        id\n        name\n        jobs {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CustomerConfigurationDocument,
    "\n  query Feins {\n    feins {\n      id\n      name\n      tin\n      isActive\n      workTaxLocationIds\n      states {\n        name\n        eins {\n          id\n          taxName\n          ein\n          frequency {\n            name\n          }\n          sutaRates {\n            rate\n            name\n            isMajor\n            isExempt\n            excludeFromCombinedRate\n          }\n        }\n      }\n    }\n  }\n": types.FeinsDocument,
    "\n  query OnboardingPermissions {\n    payrollOnboardingPermissions\n  }\n": types.OnboardingPermissionsDocument,
    "\n  query CustomerInfo {\n    # note: due to a bug in the supergraph, even though customer and payrollCustomer return the same type -- we must query fields separately\n    customer {\n      locations {\n        name\n        status {\n          code\n        }\n      }\n    }\n    payrollCustomer {\n      name\n    }\n    customerConfiguration {\n      workTaxLocations {\n        name\n        address {\n          state\n          country\n        }\n      }\n    }\n    customerSettings {\n      HAS_PREVIOUS_PAYROLL_PROVIDER\n    }\n  }\n": types.CustomerInfoDocument,
    "\n  fragment ChecklistStateFragment on ChecklistState {\n    customerUuid\n    items {\n      key\n      status\n      title\n      assignedTo\n      subItems {\n        key\n        status\n        title\n        assignedTo\n      }\n    }\n  }\n": types.ChecklistStateFragmentFragmentDoc,
    "\n  mutation UpdateTask($taskKey: String!, $status: TaskStatus!) {\n    updateTask(taskKey: $taskKey, status: $status) {\n      ...ChecklistStateFragment\n    }\n  }\n": types.UpdateTaskDocument,
    "\n  mutation UpdatePreviousProvider($hasPreviousPayrollProvider: Boolean!) {\n    setHasPreviousPayrollProvider(\n      hasPreviousPayrollProvider: $hasPreviousPayrollProvider\n    ) {\n      message\n    }\n  }\n": types.UpdatePreviousProviderDocument,
    "\n  query upcoming {\n    upcoming {\n      payDate\n    }\n  }\n": types.UpcomingDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ChecklistItems {\n    checklistState: resyncTasks {\n      customerUuid\n      items {\n        # change these to fragments if the list of item data grows\n        key\n        status\n        title\n        assignedTo\n        subItems {\n          key\n          status\n          title\n          assignedTo\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ChecklistItems {\n    checklistState: resyncTasks {\n      customerUuid\n      items {\n        # change these to fragments if the list of item data grows\n        key\n        status\n        title\n        assignedTo\n        subItems {\n          key\n          status\n          title\n          assignedTo\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PaycheckDeliveryMethod {\n    paycheckDeliverySettings {\n      method\n      needsPdfFile\n      usingToastCheckStock\n    }\n  }\n"): (typeof documents)["\n  query PaycheckDeliveryMethod {\n    paycheckDeliverySettings {\n      method\n      needsPdfFile\n      usingToastCheckStock\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Jobs($parentLevelUuid: String) {\n    jobs(parentLevelUuid: $parentLevelUuid) {\n      uuid\n      name\n      isOrphan\n    }\n  }\n"): (typeof documents)["\n  query Jobs($parentLevelUuid: String) {\n    jobs(parentLevelUuid: $parentLevelUuid) {\n      uuid\n      name\n      isOrphan\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerConfiguration {\n    customerConfiguration {\n      locations {\n        id\n        name\n        jobs {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomerConfiguration {\n    customerConfiguration {\n      locations {\n        id\n        name\n        jobs {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Feins {\n    feins {\n      id\n      name\n      tin\n      isActive\n      workTaxLocationIds\n      states {\n        name\n        eins {\n          id\n          taxName\n          ein\n          frequency {\n            name\n          }\n          sutaRates {\n            rate\n            name\n            isMajor\n            isExempt\n            excludeFromCombinedRate\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Feins {\n    feins {\n      id\n      name\n      tin\n      isActive\n      workTaxLocationIds\n      states {\n        name\n        eins {\n          id\n          taxName\n          ein\n          frequency {\n            name\n          }\n          sutaRates {\n            rate\n            name\n            isMajor\n            isExempt\n            excludeFromCombinedRate\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OnboardingPermissions {\n    payrollOnboardingPermissions\n  }\n"): (typeof documents)["\n  query OnboardingPermissions {\n    payrollOnboardingPermissions\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerInfo {\n    # note: due to a bug in the supergraph, even though customer and payrollCustomer return the same type -- we must query fields separately\n    customer {\n      locations {\n        name\n        status {\n          code\n        }\n      }\n    }\n    payrollCustomer {\n      name\n    }\n    customerConfiguration {\n      workTaxLocations {\n        name\n        address {\n          state\n          country\n        }\n      }\n    }\n    customerSettings {\n      HAS_PREVIOUS_PAYROLL_PROVIDER\n    }\n  }\n"): (typeof documents)["\n  query CustomerInfo {\n    # note: due to a bug in the supergraph, even though customer and payrollCustomer return the same type -- we must query fields separately\n    customer {\n      locations {\n        name\n        status {\n          code\n        }\n      }\n    }\n    payrollCustomer {\n      name\n    }\n    customerConfiguration {\n      workTaxLocations {\n        name\n        address {\n          state\n          country\n        }\n      }\n    }\n    customerSettings {\n      HAS_PREVIOUS_PAYROLL_PROVIDER\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ChecklistStateFragment on ChecklistState {\n    customerUuid\n    items {\n      key\n      status\n      title\n      assignedTo\n      subItems {\n        key\n        status\n        title\n        assignedTo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ChecklistStateFragment on ChecklistState {\n    customerUuid\n    items {\n      key\n      status\n      title\n      assignedTo\n      subItems {\n        key\n        status\n        title\n        assignedTo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTask($taskKey: String!, $status: TaskStatus!) {\n    updateTask(taskKey: $taskKey, status: $status) {\n      ...ChecklistStateFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTask($taskKey: String!, $status: TaskStatus!) {\n    updateTask(taskKey: $taskKey, status: $status) {\n      ...ChecklistStateFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePreviousProvider($hasPreviousPayrollProvider: Boolean!) {\n    setHasPreviousPayrollProvider(\n      hasPreviousPayrollProvider: $hasPreviousPayrollProvider\n    ) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation UpdatePreviousProvider($hasPreviousPayrollProvider: Boolean!) {\n    setHasPreviousPayrollProvider(\n      hasPreviousPayrollProvider: $hasPreviousPayrollProvider\n    ) {\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query upcoming {\n    upcoming {\n      payDate\n    }\n  }\n"): (typeof documents)["\n  query upcoming {\n    upcoming {\n      payDate\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;