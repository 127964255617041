import { useFeature, useFeatureBase } from '@toasttab/ec-features'

export enum FeatureFlag {
  USE_EIN_FOR_CUSTOMER = 'ec-px-enable-ein-customers',
  USE_PONC = 'ec-poop-show-payroll-onboarding-checklist',
  USE_COMPANY_SUMMARY = 'ec-px-display-company-summary',
  USE_COMPANY_SUMMARY_V3 = 'ec-px-display-company-summary-v3',
  // This one is a misnomer -- it started as only the welcome screen, but to keep switcher/multiloc. support
  // in sync with the welcome screen experience, we are using this flag to cover switcher/multiloc. support also
  USE_WELCOME_SCREEN = 'ec-px-display-welcome-screen',
  CHECKLIST_RESHOW_TIMER = 'ec-px-payroll-onboarding-checklist-reshow-timer', // minutes
  SHOW_DIRECT_DEPOSIT_LINK = 'ec-pcard-show-bulk-import-direct-deposit-import-spa',
  SHOW_BENEFITS_STEP = 'ec-pcard-simply-insured'
}

export const useEinForCustomer = () =>
  useFeature(FeatureFlag.USE_EIN_FOR_CUSTOMER, false)
export const usePonc = () => useFeature(FeatureFlag.USE_PONC, false)
export const useCompanySummary = () =>
  useFeature(FeatureFlag.USE_COMPANY_SUMMARY, false)
export const useCompanySummaryV3 = () =>
  useFeature(FeatureFlag.USE_COMPANY_SUMMARY_V3, false)
export const useGAView = () => useFeature(FeatureFlag.USE_WELCOME_SCREEN, false)
export const useBulkActionDirectDepositLink = () =>
  useFeature(FeatureFlag.SHOW_DIRECT_DEPOSIT_LINK, false)

// Pulls the amount of time before forcing a redisplay of the checklist to onboarding customers
// TODO Will be removed once experimenting is done and we can determine a reasonable static constant
export const useChecklistReshowTimer = () => {
  const defaultReshowTimer = 60
  const reshowTimer = useFeatureBase<number>(
    FeatureFlag.CHECKLIST_RESHOW_TIMER,
    defaultReshowTimer
  )
  return reshowTimer > 0 ? reshowTimer : defaultReshowTimer
}
