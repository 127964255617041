import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import '../styles.css'
import { ItemSubTask } from '../common/ItemSubTask'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { disableItemActions } from '../../utils/status'
import { Item } from '@local/api/generated/gql/graphql'
import { useTranslation } from 'react-i18next'
import { Badge } from '@toasttab/buffet-pui-badge'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'

const Header = () => {
  const { t } = useTranslation('ponc')
  return (
    <div className='pb-2 w-fit'>
      <Badge color='info' variant='statusSm'>
        {t('common.completedByOC')}
      </Badge>
    </div>
  )
}

const Content = ({ content }: { content: string }) => <p>{content}</p>

const Footer = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')
  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_REVIEW_PREPARE_TO_RUN_PAYROLL,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  if (isAdmin) {
    return (
      <>
        <Button variant='secondary' onClick={() => setChecklistOpen(false)}>
          {t(`${item.key}.review`)}
        </Button>
        <Button onClick={complete} disabled={disableItemActions(item)}>
          {t('common.complete')}
        </Button>
      </>
    )
  } else {
    return <Button onClick={closeFocus}>{t('common.gotIt')} </Button>
  }
}

export const PrepareToRun = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { t } = useTranslation('ponc')
  return (
    <ItemSubTask
      header={<Header />}
      title={t(`${item.key}.title`)}
      content={<Content content={t(`${item.key}.content`)} />}
      footer={<Footer isAdmin={isAdmin} closeFocus={closeFocus} item={item} />}
    />
  )
}
