import {
  DeliveryMethod,
  Item,
  PaycheckDeliveryMethodDocument
} from '@local/api/generated/gql/graphql'
import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'

import { useQuery } from '@apollo/client'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { Badge } from '@toasttab/buffet-pui-badge'
import { ItemSubTask } from '../common/ItemSubTask'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { disableItemActions } from '../../utils/status'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../common/loader'
import { Error } from '../../common/error'
import { useEcCompanySessionStorageState } from '@toasttab/ec-storage'

const mapDeliveryMethodToHuman = (method: DeliveryMethod): string => {
  switch (method) {
    case 'Invalid':
      return 'Invalid delivery method'
    case 'PayYourOwnWay':
      return 'Self-Written'
    case 'SelfPrinted':
      return 'Self-Printed'
    case 'ToastPrinted':
      return 'Toast-Printed'
    case 'NotRecorded':
    default:
      return 'Unknown delivery method'
  }
}

const OptionExplanation = ({
  title,
  description
}: {
  title: string
  description: React.ReactNode
}) => (
  <div className='pt-3'>
    <div className='mb-2 font-semibold'>{title}</div>
    <div>{description}</div>
  </div>
)

export const PaycheckDeliveryMethod = ({
  closeFocus,
  item
}: {
  closeFocus: () => void
  item: Item
}) => {
  const { data, loading, error } = useQuery(PaycheckDeliveryMethodDocument)
  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')

  if (loading) return <Loader />

  if (error) return <Error />

  if (!data) return null

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_PAY_PAYCHECK_DELIVERY_METHOD,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  return (
    <ItemSubTask
      title={t(`${item.key}.title`)}
      content={
        <div>
          <div className='mb-6'>
            {t(`${item.key}.description`)}{' '}
            <span className='font-bold'>
              {mapDeliveryMethodToHuman(data.paycheckDeliverySettings.method)}
            </span>
            {t(`${item.key}.description2`)}
          </div>
          <div className='mb-2'>{t(`${item.key}.methods`)}</div>
          <div className='space-y-4 divide-y'>
            <OptionExplanation
              title='Self-written'
              description={t(`${item.key}.selfWritten`)}
            />
            <OptionExplanation
              title='Self-printed'
              description={t(`${item.key}.selfPrinted`)}
            />
            <OptionExplanation
              title='Toast-printed'
              description={t(`${item.key}.toastPrinted`)}
            />
          </div>
        </div>
      }
      footer={
        <>
          <Button variant='secondary' onClick={() => setChecklistOpen(false)}>
            {t(`${item.key}.change`)}
          </Button>
          <Button onClick={complete} disabled={disableItemActions(item)}>
            {t(`${item.key}.confirm`)}
          </Button>
        </>
      }
    />
  )
}

export const PayCard = ({
  closeFocus,
  item
}: {
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')

  return (
    <ItemSubTask
      title={t(`${item.key}.stepTitle`)}
      content={
        <div className='pt-2'>
          <div className='p-4 mb-4 border-2 border-gray-100 rounded-lg'>
            <div className='mb-6 type-subhead'>
              {t(`${item.key}.description`)}
            </div>
            <img
              src='https://cdn.toasttab.com/static/cdbff9f3d2434a1720cbb2963f8152eba5cda385/projects/ec/employee-app-spa/toast-cards.png'
              alt={t(`${item.key}.imgText`)}
              className='w-full h-full'
            />
          </div>
          <div className='text-center type-caption text-disabled'>
            {t(`${item.key}.disclaimer`)}{' '}
            <a
              href='https://pos.toasttab.com/products/toast-pay-card-and-pay-out/#availability'
              target='_blank'
              rel='noreferrer'
              className='underline text-disabled'
            >
              {t(`${item.key}.info`)}
            </a>
          </div>
        </div>
      }
      footer={
        <>
          <Button
            disabled={disableItemActions(item)}
            onClick={() => {
              updateTask(
                ChecklistItemKey.PAYROLL_PAY_OFFER_TOAST_PAY_CARD,
                TaskStatus.COMPLETED
              )
              closeFocus()
            }}
            variant='secondary'
          >
            {t(`${item.key}.later`)}
          </Button>

          <Button
            disabled={disableItemActions(item)}
            onClick={() => {
              window.open(
                'https://www.toasttab.com/restaurants/admin/employee-card/onboarding',
                '_blank'
              )
              updateTask(
                ChecklistItemKey.PAYROLL_PAY_OFFER_TOAST_PAY_CARD,
                TaskStatus.COMPLETED
              )
              closeFocus()
            }}
            iconRight={<LaunchIcon accessibility='decorative' />}
          >
            <span> {t(`${item.key}.setup`)}</span>
          </Button>
        </>
      }
    />
  )
}

const ListDot = ({ text }: { text: string }) => (
  <li className='pt-4 pb-3 pl-1 ml-4 list-disc'>{text}</li>
)

export const EarningsEtAl = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')

  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  return (
    <ItemSubTask
      title={t(`${item.key}.title`)}
      header={
        <div className='pb-2 w-fit'>
          <Badge variant='statusSm' color='info'>
            {t('common.completedByOC')}
          </Badge>
        </div>
      }
      content={
        <>
          <div className='mb-4'>{t(`${item.key}.description`)}</div>
          <div className='mb-2'> {t(`${item.key}.example`)}</div>
          <ul className='py-2 space-y-2 divide-y'>
            <ListDot text={t(`${item.key}.bonuses`)} />
            <ListDot text={t(`${item.key}.deductions`)} />
            <ListDot text={t(`${item.key}.garnishments`)} />
            <ListDot text={t(`${item.key}.pto`)} />
            <ListDot text={t(`${item.key}.leave`)} />
          </ul>
        </>
      }
      footer={
        isAdmin ? (
          <div className='flex flex-col space-y-4'>
            <Button variant='secondary' onClick={() => setChecklistOpen(false)}>
              {t(`${item.key}.view`)}
            </Button>
            <Button
              disabled={disableItemActions(item)}
              onClick={() => {
                updateTask(
                  ChecklistItemKey.PAYROLL_PAY_DEDUCTIONS_TIME_OFF,
                  TaskStatus.COMPLETED
                )
                closeFocus()
              }}
            >
              {t('common.complete')}
            </Button>
          </div>
        ) : (
          <div className='flex flex-col space-y-4'>
            <Button onClick={closeFocus}>{t('common.gotIt')}</Button>
          </div>
        )
      }
    ></ItemSubTask>
  )
}

export const TipsSettings = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  closeFocus: () => void
  item: Item
}) => {
  const { updateTask } = useUpdateTask()
  const { t } = useTranslation('ponc')

  const [_, setChecklistOpen] = useEcCompanySessionStorageState(
    'ec-payroll-onboarding-checklist-is-open'
  )

  return (
    <ItemSubTask
      title={t(`${item.key}.select`)}
      header={
        <div className='pb-2 w-fit'>
          <Badge variant='statusSm' color='info'>
            {t('common.completedByOC')}
          </Badge>
        </div>
      }
      content={
        <div>
          <div className='mb-4'>{t(`${item.key}.description`)}</div>
          <div className='mb-2'> {t(`${item.key}.types`)}</div>
          <div className='space-y-4 divide-y'>
            <OptionExplanation
              title={t(`${item.key}.pooled.title`)}
              description={
                <span>
                  {t(`${item.key}.pooled.setup`)}{' '}
                  <a
                    className='underline'
                    href='https://central.toasttab.com/s/article/Getting-Started-with-Toast-Tips-Manager-How-to-Pool-Share-Tips'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t(`${item.key}.pooled.link`)}
                  </a>{' '}
                  {t(`${item.key}.pooled.description`)}
                </span>
              }
            />
            <OptionExplanation
              title={t(`${item.key}.normal.title`)}
              description={t(`${item.key}.normal.description`)}
            />
            <OptionExplanation
              title={t(`${item.key}.noTips.title`)}
              description={t(`${item.key}.noTips.description`)}
            />
          </div>
        </div>
      }
      footer={
        isAdmin ? (
          <div className='flex flex-col space-y-4'>
            <Button onClick={() => setChecklistOpen(false)} variant='secondary'>
              {t(`${item.key}.close`)}
            </Button>
            <Button
              disabled={disableItemActions(item)}
              onClick={() => {
                updateTask(
                  ChecklistItemKey.PAYROLL_PAY_TIPS_SETTINGS,
                  TaskStatus.COMPLETED
                )
                closeFocus()
              }}
            >
              {t('common.complete')}
            </Button>
          </div>
        ) : (
          <div className='flex flex-col space-y-4'>
            <Button onClick={closeFocus}>{t('common.gotIt')}</Button>
          </div>
        )
      }
    ></ItemSubTask>
  )
}
